import { useTranslation } from "react-i18next";

function Support() {
    const { t } = useTranslation();

    return (
        // wrapper
        <div className="relative w-full overflow-hidden bg-[#27283E] pt-16">
            {/* bg */}
            <img
                src="assets/imgs/views/support/support-bg.png"
                alt=""
                className="absolute left-0 top-0 z-0 h-full w-full object-cover object-center"
            />
            <div className="relative z-[1] w-full px-8 lg:px-[120px]">
                {/* section content */}
                <div className="mx-auto flex w-full max-w-[1200px] flex-col items-center gap-6 py-16 lg:gap-12 lg:py-[120px]">
                    {/* text group */}
                    <div className="flex max-w-[800px] flex-col items-center gap-6">
                        {/* title */}
                        <span className="text-center text-5xl font-bold text-[#fff] lg:text-[56px]">
                            {t("support.titleText")}
                        </span>
                        {/* description */}
                        <span className="text-center text-base text-[#fff]">
                            {t("support.descriptionText")}
                        </span>
                    </div>
                    {/* contact group */}
                    <div className="flex w-full flex-col gap-2 lg:items-center lg:gap-6">
                        {/* <div className="flex gap-4">
                            <img
                                src="assets/imgs/views/support/icon-phone.svg"
                                alt=""
                                className="h-5 w-5"
                            />
                            <span className="text-base text-[#fff]">
                                Contact Us
                            </span>
                        </div> */}
                        <div className="flex gap-4">
                            <img
                                src="assets/imgs/views/support/icon-envelope.svg"
                                alt=""
                                className="h-5 w-5"
                            />
                            <span className="text-base text-[#fff]">
                                info@1xprime.com
                            </span>
                        </div>
                        {/* <div className="flex gap-4">
                            <img
                                src="assets/imgs/views/support/icon-building.svg"
                                alt=""
                                className="h-5 w-5"
                            />
                            <span className="text-base text-[#fff]">
                                San Francisco, CA560 Bush St & 20th Ave, Apt5
                                San Francisco, 230909
                            </span>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Support;
