function Section3() {
    return (
        <div className="w-full bg-[#EAF5FA] px-8 lg:px-[120px]">
            <div className="mx-auto flex w-full max-w-[1200px] flex-col gap-6 py-16 lg:gap-16 lg:pb-[120px] lg:pt-[100px]">
                {/* title */}
                <span className="text-center text-[40px] font-bold text-[#151d24] lg:text-[56px]">
                    How does it work?
                </span>
                {/* steps */}
                <div className="flex w-full flex-col pt-10 lg:flex-row lg:pt-0">
                    {/* step1 */}
                    <div className="flex w-full gap-8 lg:flex-col lg:gap-8">
                        {/* step block */}
                        <div className="flex gap-4 border-r-2 border-dashed border-[#0066ff] lg:flex-col lg:border-b-2 lg:border-r-0">
                            {/* step text */}
                            <span className="w-10 text-[32px] font-bold text-[#0066ff] lg:text-5xl">
                                01
                            </span>
                            {/* dot */}
                            <div className="h-[10px] w-[10px] translate-x-[6px] rounded-full bg-[#0066ff] lg:-translate-x-[6px] lg:translate-y-[6px]"></div>
                        </div>
                        {/* text block */}
                        <div className="flex flex-col gap-4 pb-10 lg:pr-10">
                            <span className="text-xl font-semibold text-[#151d24] lg:text-2xl">
                                Open Telegram
                            </span>
                            <span className="text-sm text-[#62737f]">
                                Create an account for free using your email
                                address, google, facebook or telegram accounts
                            </span>
                        </div>
                    </div>
                    {/* step2 */}
                    <div className="flex w-full gap-8 lg:flex-col lg:gap-8">
                        {/* step block */}
                        <div className="flex gap-4 border-r-2 border-dashed border-[#0066ff] lg:flex-col lg:border-b-2 lg:border-r-0">
                            {/* step text */}
                            <span className="w-10 text-[32px] font-bold text-[#0066ff] lg:text-5xl">
                                02
                            </span>
                            {/* dot */}
                            <div className="h-[10px] w-[10px] translate-x-[6px] rounded-full bg-[#0066ff] lg:-translate-x-[6px] lg:translate-y-[6px]"></div>
                        </div>
                        {/* text block */}
                        <div className="flex flex-col gap-4 pb-10 lg:pr-10">
                            <span className="text-xl font-semibold text-[#151d24] lg:text-2xl">
                                Follow us
                            </span>
                            <span className="text-sm text-[#62737f]">
                                Accessible investing for all, minimal initial
                                investment broadens market access.
                            </span>
                        </div>
                    </div>
                    {/* step3 */}
                    <div className="flex w-full gap-8 lg:flex-col lg:gap-8">
                        {/* step block */}
                        <div className="flex gap-4 border-r-2 border-dashed border-[#0066ff] lg:flex-col lg:border-b-2 lg:border-r-0">
                            {/* step text */}
                            <span className="w-10 text-[32px] font-bold text-[#0066ff] lg:text-5xl">
                                03
                            </span>
                            {/* dot */}
                            <div className="h-[10px] w-[10px] translate-x-[6px] rounded-full bg-[#0066ff] lg:-translate-x-[6px] lg:translate-y-[6px]"></div>
                        </div>
                        {/* text block */}
                        <div className="flex flex-col gap-4 lg:pr-10">
                            <span className="text-xl font-semibold text-[#151d24] lg:text-2xl">
                                Start Trading!
                            </span>
                            <span className="text-sm text-[#62737f]">
                                Withdraw your money in an instant using a wide
                                range of available payment systems.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section3;
