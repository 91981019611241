function Section2() {
    return (
        <div className="w-full bg-[#fff] px-8 lg:px-[120px]">
            <div className="mx-auto w-full max-w-[1200px]">
                <div className="flex w-full flex-col gap-8 py-16 lg:gap-10 lg:py-[100px]">
                    <span className="text-[40px] font-semibold text-[#151d24] lg:text-center lg:text-[56px]">
                        About Us
                    </span>
                    <div className="text-base text-[#62737f] lg:text-center">
                        <p>HT Technology FZE is an NFA-regulated forex and CFD provider, offering innovative trading solutions for active day traders, scalpers, and newcomers to CFD trading. We provide our clients with advanced trading platforms, low latency connectivity, and superior liquidity, revolutionizing online forex and CFD trading by granting access to market pricing and liquidity like never before.</p>
                        <p>Our management team boasts extensive experience in the Forex, CFD, and Equity markets across Asia, Europe, and North America. This experience has been crucial in selecting the finest technology solutions and securing the best liquidity available in the market.</p>
                    </div>
                    <span className="text-[24px] font-semibold text-[#151d24] lg:text-center lg:text-[32px]">
                        Why HT Technology FZE?
                    </span>
                    <div className="text-base text-[#62737f] lg:text-center">
                        <p>Founded in 2020, HT Technology FZE was established with the vision of bridging the gap between retail and institutional clients by offering a trading solution previously only available to investment banks and high net worth individuals.</p>
                    </div>
                    <span className="text-[24px] font-semibold text-[#151d24] lg:text-center lg:text-[32px]">
                        Our Mission
                    </span>
                    <div className="text-base text-[#62737f] lg:text-center">
                        <p>Our mission is to create the best and most transparent trading environment for both retail and institutional clients, allowing traders to focus more on their trading.</p>
                    </div>
                    <span className="text-[24px] font-semibold text-[#151d24] lg:text-center lg:text-[32px]">
                        Regulatory Compliance
                    </span>
                    <div className="text-base text-[#62737f] lg:text-center">
                        <p>As an NFA-regulated entity, we are committed to compliance with all relevant laws, rules, regulations, policies, and standards, and we ensure the strict management of client funds.</p>
                    </div>
                    <span className="text-[24px] font-semibold text-[#151d24] lg:text-center lg:text-[32px]">
                        Confidentiality
                    </span>
                    <div className="text-base text-[#62737f] lg:text-center">
                        <p>We maintain appropriate controls and monitor the transfer of confidential and sensitive client information, ensuring that client information is never deliberately disclosed unless required by law.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Section2;
