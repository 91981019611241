function Section1() {
    return (
        // wrapper
        <div className="relative w-full overflow-hidden bg-[#27283E] pt-16">
            {/* bg */}
            <img
                src="assets/imgs/views/home/section1/section1-bg.svg"
                alt=""
                className="absolute left-0 top-0 z-0 h-full w-full object-cover object-left-top"
            />
            <div className="relative z-[1] w-full px-8 lg:px-[120px]">
                {/* section content */}
                <div className="mx-auto flex w-full max-w-[1200px] flex-col gap-6 pt-16 lg:flex-row lg:items-center lg:justify-between lg:pt-0">
                    {/* main content */}
                    <div className="flex flex-col items-center gap-6 lg:items-start lg:gap-16 lg:pb-16">
                        {/* text group */}
                        <div className="flex flex-col items-center gap-4 lg:items-start lg:gap-6">
                            {/* title */}
                            <span className="text-center text-5xl font-bold text-[#fff] lg:text-left lg:text-[72px]">
                                Prime Trade Platform
                            </span>
                            {/* description */}
                            <span className="text-center text-base text-[#93b3bf] lg:text-left">
                                Prime Trade platform provides traders with a
                                simple, intuitive interface as well as best in
                                class trading experience. Start trading with
                                Prime Trade today.
                            </span>
                        </div>
                        {/* trade button */}
                        <a href="https://app.1xprime.com" target="_blank">
                            <button className="h-14 rounded-2xl bg-[#0066ff] px-16 text-center text-base font-bold text-[#e5f0f4]">
                                Trade Now
                            </button>
                        </a>
                    </div>
                    {/* phone image */}
                    <img
                        src="assets/imgs/views/trade/section1/phone.png"
                        alt=""
                        className="w-full translate-x-[4.5%] object-contain object-center lg:w-1/2 lg:translate-x-0"
                    />
                </div>
            </div>
        </div>
    );
}

export default Section1;
