function Section2() {
    return (
        <div className="w-full bg-[#fff] px-8 lg:px-[120px]">
            {/* section content */}
            <div className="mx-auto flex w-full max-w-[1200px] flex-col gap-6 pt-16 lg:flex-row lg:items-end lg:justify-between lg:gap-20 lg:pt-0">
                {/* main content */}
                <div className="flex flex-col items-start gap-8 lg:order-2 lg:my-auto lg:py-10">
                    {/* title */}
                    <span className="w-full p-0 text-[40px] font-bold leading-[1.2] text-[#151d24] lg:text-[56px]">
                        The Perfect Solution for Trading on Your Mini-APP
                    </span>
                    {/* description */}
                    <ul className="w-full text-base text-[#62737f]">
                        <li className="text-[#62737f]">
                            Add markets to the watchlist for easy viewing of
                            market trends with just a few clicks.
                        </li>
                        <li className="text-[#62737f]">
                            Turn on alerts to receive notifications for price
                            alerts and other useful updates
                        </li>
                        <li className="text-[#62737f]">
                            Split charts allow you to view the same market
                            across different timeframes at the same time
                        </li>
                        <li className="text-[#62737f]">
                            Check contract specification, trading sentiment, and
                            trading analysis on one screen
                        </li>
                    </ul>
                    {/* trade button */}
                    <a href="https://app.1xprime.com" target="_blank">
                        <button className="h-14 w-full rounded-2xl bg-[#0066ff] px-16 text-center text-base font-bold text-[#e5f0f4] lg:w-auto">
                            Trade Now
                        </button>
                    </a>
                </div>
                {/* phone image */}
                <img
                    src="assets/imgs/views/trade/section2/phone.png"
                    alt=""
                    className="w-full object-contain lg:order-1 lg:w-[40%] lg:translate-x-0"
                />
            </div>
        </div>
    );
}

export default Section2;
