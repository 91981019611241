function Section3() {
    return (
        <div className="w-full bg-[#EAF5FA] px-8 lg:px-[120px]">
            <div className="mx-auto w-full max-w-[1200px]">
                <div className="flex w-full flex-col gap-6 py-16 lg:gap-16 lg:py-[120px]">
                    <span className="text-center text-[40px] font-semibold text-[#151d24] lg:text-[56px]">
                        Why Choose Us?
                    </span>
                    <div className="flex w-full flex-col items-center gap-6 lg:flex-row lg:justify-between">
                        <div className="flex flex-wrap items-center justify-center gap-4">
                            <img
                                src="assets/imgs/views/aboutUs/section3/icon-check.svg"
                                alt=""
                                className="h-8 w-8"
                            />
                            <span className="whitespace-nowrap text-2xl font-semibold text-[#151d24]">
                                Well Regulated
                            </span>
                        </div>
                        <div className="flex flex-wrap items-center justify-center gap-4">
                            <img
                                src="assets/imgs/views/aboutUs/section3/icon-check.svg"
                                alt=""
                                className="h-8 w-8"
                            />
                            <span className="whitespace-nowrap text-2xl font-semibold text-[#151d24]">
                                Transparency
                            </span>
                        </div>
                        <div className="flex flex-wrap items-center justify-center gap-4">
                            <img
                                src="assets/imgs/views/aboutUs/section3/icon-check.svg"
                                alt=""
                                className="h-8 w-8"
                            />
                            <span className="whitespace-nowrap text-2xl font-semibold text-[#151d24]">
                                Flexibility
                            </span>
                        </div>
                        <div className="flex flex-wrap items-center justify-center gap-4">
                            <img
                                src="assets/imgs/views/aboutUs/section3/icon-check.svg"
                                alt=""
                                className="h-8 w-8"
                            />
                            <span className="whitespace-nowrap text-2xl font-semibold text-[#151d24]">
                                Honesty and Fairness
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Section3;
