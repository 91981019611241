function Section4() {
    return (
        <div className="w-full bg-[#fff] px-8 lg:px-[120px]">
            {/* section content */}
            <div className="mx-auto flex w-full max-w-[1200px] flex-col gap-6 pt-16 lg:flex-row lg:items-end lg:justify-between lg:gap-20 lg:pt-0">
                {/* main content */}
                <div className="flex flex-col items-start gap-8 lg:my-auto lg:py-10">
                    {/* title */}
                    <span className="w-full p-0 text-[40px] font-bold leading-[1.2] text-[#151d24] lg:text-[56px]">
                        Trade anywhere, anytime with Prime Trade H5
                    </span>
                    {/* description */}
                    <ul className="w-full text-base text-[#62737f]">
                        <li className="text-[#62737f]">
                            Trade hundreds of popular markets with your
                            fingertips
                        </li>
                        <li className="text-[#62737f]">
                            Monitor live markets trend, view trading analysis,
                            and check the latest economic news in real-time
                        </li>
                        <li className="text-[#62737f]">
                            Access different types of charts on the go with
                            technical indicators and drawing tools for your
                            technical analysis.
                        </li>
                    </ul>
                    {/* trade button */}
                    <a href="https://app.1xprime.com" target="_blank">
                        <button className="h-14 w-full rounded-2xl bg-[#0066ff] px-16 text-center text-base font-bold text-[#e5f0f4] lg:w-auto">
                            Trade Now
                        </button>
                    </a>
                </div>
                {/* phone image */}
                <img
                    src="assets/imgs/views/trade/section4/phone.png"
                    alt=""
                    className="w-full object-contain lg:w-[40%] lg:translate-x-0"
                />
            </div>
        </div>
    );
}

export default Section4;
