import "./../policy.css";

function Terms() {
    return (
        <div className="w-full">
            <noindex>
                <h2>RETAIL CLIENT AGREEMENT</h2>
                <p>
                    Note: This Retail Client Agreement is drawn up in the
                    English language. In the event any translation of this
                    Retail Client Agreement is prepared for convenience or any
                    other purpose, or there is any conflict between the English
                    version and any subsequent translation into any other
                    language, the provisions of the English version shall
                    prevail.
                </p>
                <p>
                    This Retail Client Agreement, along with any additional
                    terms or policies incorporated herein by reference, as
                    amended from time to time, (hereinafter the “Agreement”)
                    sets out the terms under which all business dealings shall
                    be transacted between the customer (“You”, “Client”) and us,
                    EOLabs LLC (hereinafter referred to as the “Company”).
                </p>
                <p>
                    HT Technology FZE, with National Futures Association (NFA) and the license number is 0562772, having its
                    registered address at Block B -B24-190, Sharjah provides payment software solutions.
                </p>
                <p>
                    By clicking to accept, and by accessing the Site or using
                    the services provided by the Company, the Customer
                    understands, represents, acknowledges and agrees to be
                    legally bound by this Agreement, including Payment Policy,
                    Return and Refund Policy, Privacy Policy, Bonus Terms and
                    Conditions, AML and KYC Policy, and 1-Click Transaction
                    Agreement Terms and Conditions, as amended from time to
                    time, as if the Customer had manually signed this Agreement.
                </p>
                <p>
                    For Your benefit and protection, we strongly recommend that
                    You take sufficient time to read the Agreement together with
                    other policies and agreements available via
                    https://www.1xprime.com/ (the “Site”) carefully prior
                    to accepting the Agreement, and/or opening a trading
                    account, and/or performing any transactions.
                </p>
                <p>
                    If You do not accept this Agreement, please refrain from
                    using the Site, and/or Mobile Apps, and/or opening an
                    account. Your use of the Site, and/or Mobile Apps
                    constitutes acceptance of this Agreement.
                </p>
                <p>
                    HT Technology FZE, Company No 4828, having its registered
                    address at: Block B -B24-190, Sharjah.
                </p>
                <p>
                    Block B -B24-190, Sharjah, registered address: Block B -B24-190, Sharjah, provides payment
                    agent services for https://www.1xprime.com/
                </p>
                <h3>1. DEFINITIONS AND INTERPRETATION</h3>
                <p>
                    1.1. Unless otherwise provided in this Agreement, the
                    following terms shall have the following meanings:
                </p>
                <p>
                    “Account” means an account opened by Client with the Company
                    for trading.
                </p>
                <p>
                    “Agreement” means this Retail Client Agreement, including
                    Payment Policy, Return and Refund Policy, Privacy Policy,
                    Bonus Terms and Conditions, AML and KYC Policy, and 1-Click
                    Transaction Agreement Terms and Conditions, as amended from
                    time to time.
                </p>
                <p>
                    “Dormant Account” means Client’s Account where Client has
                    not initiated any transaction for a period of three (3)
                    months.
                </p>
                <p>
                    “FATCA” is an abbreviation for the Foreign Account Tax
                    Compliance Act.
                </p>
                <p>
                    “Mobile Apps” mean mobile apps available for Android and iOS
                    in order to optimize the use of the Services on a smartphone
                    or tablet.
                </p>
                <p>
                    “Transaction” means any type of transaction performed by
                    Client in the Account, including but not limited to buy and
                    sell transactions related to financial instruments, deposits
                    and withdrawals.
                </p>
                <p>
                    “Services” means the services provided to Client by the
                    Company.
                </p>
                <p>
                    “Site” means the website of the Company,{" "}
                    <a href="https://www.1xprime.com/">
                        https://www.1xprime.com/
                    </a>
                </p>
                <p>“US Reportable Person” means:</p>
                <ul>
                    <li>a U.S. citizen (including dual citizen);</li>
                    <li>a U.S. resident alien for tax purposes;</li>
                    <li>a domestic partnership;</li>
                    <li>a domestic corporation;</li>
                    <li>
                        <p>any estate other than a foreign estate;</p>
                    </li>
                </ul>
                <p>Any trust if:</p>
                <ul>
                    <li>
                        a court within the United States is able to exercise
                        primary supervision over the administration of the
                        trust;
                    </li>
                    <li>
                        one or more United States persons have the authority to
                        control all substantial decisions of the trust;
                    </li>
                    <li>
                        <p>any other person that is not a foreign person.</p>
                    </li>
                </ul>
                <p>1.2. For purposes of this Agreement:</p>
                <ul>
                    <li>
                        the words “include,” “includes” and “including” shall be
                        deemed to be followed by the words “without limitation”;
                    </li>
                    <li>the word “or” is not exclusive; and</li>
                    <li>
                        <p>
                            the words “herein,” “hereof,” “hereby,” “hereto” and
                            “hereunder” refer to this Agreement as a whole.
                        </p>
                    </li>
                </ul>
                <p>
                    1.3. Unless the context otherwise requires, references
                    herein:
                </p>
                <ul>
                    <li>
                        to Articles, Sections, and Schedules mean the Articles
                        and Sections of, and Schedules attached to, this
                        Agreement;
                    </li>
                    <li>
                        <p>
                            to an agreement, instrument or other document means
                            such agreement, instrument or other document as
                            amended, supplemented and modified from time to time
                            to the extent permitted by the provisions thereof.
                        </p>
                    </li>
                </ul>
                <p>
                    1.4. Headings of the Articles shall be used solely for
                    reference and shall not affect the content and
                    interpretation of this Agreement.
                </p>
                <p>
                    1.5. Unless the context otherwise requires, when used
                    herein, the singular shall include the plural, the plural
                    shall include the singular, and all nouns, pronouns and any
                    variations thereof shall be deemed to refer to masculine,
                    feminine or neuter, as the identity of the person or persons
                    may require.
                </p>
                <h3>2. INTRODUCTION</h3>
                <h3>Scope of this Agreement</h3>
                <p>
                    2.1. This Agreement sets out the terms under which all
                    business dealings shall be transacted between Client and the
                    Company. This Agreement governs every Transaction entered
                    into or pending between the Company and Client upon
                    acceptance of this Agreement by Client.
                </p>
                <p>
                    2.2. As of June 30, 2020 the Site is operated by EOLabs LLC
                    and all Clients' Transactions are processed and executed in
                    accordance with the laws of Saint Vincent and the
                    Grenadines.
                </p>
                <p>
                    2.3. The Company does not provide services to citizens
                    and/or residents of Australia, Austria, Belarus, Belgium,
                    Bulgaria, Canada, Croatia, Republic of Cyprus, Czech
                    Republic, Denmark, Estonia, Finland, France, Germany,
                    Greece, Hungary, Iceland, Iran, Ireland, Israel, Italy,
                    Latvia, Liechtenstein, Lithuania, Luxembourg, Malta,
                    Myanmar, Netherlands, New Zealand, North Korea, Norway,
                    Poland, Portugal, Puerto Rico, Romania, Russia, Singapore,
                    Slovakia, Slovenia, South Sudan, Spain, Sudan, Sweden,
                    Switzerland, UK, Ukraine, the USA, Yemen.
                </p>
                <p>
                    2.4. Since EOLabs LLC is not under the supervision of the
                    JFSA, it is not involved with any acts considered to be
                    offering financial products and solicitation for financial
                    services to Japan and this website is not aimed at residents
                    in Japan.
                </p>
                <p>
                    2.5. By accepting this Agreement, Client represents and
                    warrants that Client is neither citizen nor resident of any
                    of the above listed countries.
                </p>
                <h3>Commencement</h3>
                <p>
                    2.6. This Agreement shall come into force on the date of its
                    acceptance by Client.
                </p>
                <p>
                    2.7. This Agreement constitutes the entire agreement and
                    understanding between the Company and Client and supersedes
                    any and all prior and contemporaneous negotiations and
                    understandings between the Company and Client either oral or
                    written, expressed or implied.
                </p>
                <h3>Amendments</h3>
                <p>
                    2.8. The Company reserves the right to modify or update any
                    provision of this Agreement at any time for any reason and
                    any such changes to this Agreement will supersede and
                    replace any previous provisions of this Agreement effective
                    immediately upon posting on the Site.
                </p>
                <p>
                    2.9. It is your responsibility to periodically review this
                    Agreement for any changes made. The applicable version at
                    any time shall be the latest version available on the Site.
                    You expressly agree that in case of any disagreements or
                    disputes, the latest version of this Agreement available on
                    the Site at the time of the dispute shall prevail.
                </p>
                <p>
                    2.10. Your continued use of the Site, Mobile Apps and other
                    services offered by the Company after posting the changes to
                    this Agreement will constitute Your acceptance of such
                    changes.
                </p>
                <p>
                    2.11. You should discontinue using the Site, Mobile Apps and
                    other services offered by the Company if You do not agree
                    with any changes to this Agreement.
                </p>
                <h3>3. GENERAL</h3>
                <p>3.1. Client hereby acknowledges and accepts that:</p>
                <ul>
                    <li>
                        Fixed Time Trades are risky financial instruments and
                        are suitable only for professional clients, who
                        understand and have experience, and are willing to
                        assume the economic, legal and other risks associated
                        with Fixed Time Trades.
                    </li>
                    <li>
                        Company does not provide any custody services to
                        Clients.
                    </li>
                    <li>
                        Company does not provide Clients with any sort of
                        investment, financial, legal, tax advice or any other
                        form of recommendation regarding Fixed Time Trades.
                    </li>
                    <li>
                        Client, and not the Company, assumes all risks and
                        liabilities related to Fixed Time Trades.
                    </li>
                    <li>
                        Client is solely responsible for making any investment
                        decisions; and the Company assumes no liability for any
                        loss whatsoever as a result of Client’s trading
                        strategies and decisions.
                    </li>
                    <li>
                        A trading account is not a bank account and funds stored
                        in Client’s trading account will not earn any interest.
                    </li>
                    <li>
                        Company shall not be held responsible for any delays,
                        delivery failures, or any loss or damage which results
                        from the transmission of information over any network,
                        including but not limited to the Internet.
                    </li>
                    <li>
                        Company shall not be held responsible for any damages or
                        losses resulting from hacking, tampering or other
                        unauthorized access or use of the Site, Client’s data,
                        or Account.
                    </li>
                    <li>
                        Company reserves the right to keep all information
                        Client has provided to the Company during registration
                        of the Client’s Account for at least 7 years as required
                        by applicable laws and regulations.
                    </li>
                    <li>
                        Company reserves the right to process and execute
                        Client’s orders even if such orders are or may be
                        unprofitable for Client.
                    </li>
                    <li>
                        Client is solely responsible to ensure that no other
                        third party, including without limitation to any next of
                        kin and/or to members of his/her immediate family, shall
                        gain access to and/or trade through the Account opened
                        in his/her name.
                    </li>
                    <li>
                        Company shall rely on any instruction coming from any
                        person in possession of Client’s login details as if
                        these instructions were coming from Client, without the
                        Company making any further enquiry.
                    </li>
                    <li>
                        Client is liable for all losses incurred when lost or
                        stolen account credentials are used to perform Fixed
                        Time Trades by any third party.
                    </li>
                    <li>
                        Company shall not be held liable for the results of the
                        Client’s Transactions.
                    </li>
                    <li>
                        Company shall not be held liable for loss suffered by
                        Client in connection to Client’s funds held by the
                        Company, unless such loss directly arises from the
                        Company’s gross negligence, willful default or fraud.
                    </li>
                    <li>
                        Company shall not be held liable for any default of any
                        counterparty, bank, custodian or other entity where
                        Client’s money is held or with or through which
                        Transactions are performed.
                    </li>
                    <li>
                        Company, or any person or entity involved in creating,
                        producing or distributing the Website and/or the Mobile
                        Apps, shall not be held liable for any damages,
                        including, without limitation, direct, indirect,
                        incidental, special, consequential or punitive damages
                        arising out of the use of or inability to use the Site
                        and/or the Mobile Apps.
                    </li>
                    <li>
                        Client shall not submit any claims with regard to losses
                        incurred or profits not received by Client; and the
                        subject of any dispute/complaint shall not exceed the
                        total amount of Client’s deposits.
                    </li>
                    <li>
                        Company shall not be held liable towards Client for any
                        failure to perform any obligation if such failure is
                        caused by circumstances beyond the reasonable control of
                        the Company. For the purpose of this Agreement a
                        force-majeure shall include, but not limited to acts of
                        God, war, riot, arson, embargoes, civil commotion,
                        strikes, labor disputes, equipment failures, bank
                        failures, financial markets collapse or fluctuations, FX
                        rates fluctuations, strikes, fire, flood, earthquake,
                        hurricanes, tropical storms or other natural disaster or
                        casualty, shortages of labor or material, shortage of
                        transportation, facilities, fuel, energy, government
                        regulation or restriction, acts of civil or military
                        authority or terrorism, fiber cuts, weather conditions,
                        breaches or failures performed by third parties,
                        technical problems, including hardware and software
                        crashes and other malfunctions, failure of the
                        telecommunications or information services
                        infrastructure, hacking, SPAM or failure of any
                        computer, server or software disruptions on account of
                        or caused by vandalism, theft, phone service.
                    </li>
                    <li>
                        Any reliance on the information and other materials
                        available on the Site shall be at Client’s sole risk and
                        responsibility.
                    </li>
                    <li>
                        Client is solely responsible for the legitimacy of the
                        use of the Services by Client, as well as for compliance
                        with the terms and conditions of this Agreement.
                    </li>
                    <li>
                        Client is responsible to ensure that he/she will not
                        open the Account and/or use the Services offered by the
                        Company if distribution or provision of the financial
                        products or services offered by the Company would be
                        contrary to local laws or regulations.
                    </li>
                    <li>
                        Client is prohibited to open positions in the amount
                        exceeding the total balance of Client’s account or
                        amount of money deposited in Client’s account.
                    </li>
                    <li>
                        Client shall not transfer or assign any rights and
                        obligations under this Agreement to any third party
                        without the Company’s prior written consent or otherwise
                        and any such attempted assignment shall be illegal and
                        void.
                    </li>
                    <li>
                        Company reserves the right to freely assign or transfer
                        any rights and obligations under this Agreement to any
                        third party at any time without a notice or Your prior
                        consent.
                    </li>
                    <li>
                        Client is able to receive information including
                        amendments to this Agreement either via registered
                        e-mail or through the Site.
                    </li>
                    <li>
                        Client shall not register more than one Account with the
                        Company.
                    </li>
                    <li>
                        Company records all communication including any incoming
                        and outgoing telephone conversations as well as all
                        other electronic communications. Client understands and
                        accepts that he/she has been notified, in advance, about
                        the recording of any telephone conversation or
                        electronic communication between Client and the Company,
                        according to the above notification.
                    </li>
                    <li>
                        Client shall not open, directly or indirectly, any new
                        accounts with the Company if Client’s current account
                        has been suspended or blocked by the Company.
                    </li>
                    <li>
                        <p>
                            The Company reserves the right to place a hold on
                            Client’s initial deposit for a period of 180 days in
                            case Client fails/refuses to provide documents
                            requested for KYC purposes. Under no circumstances
                            shall the Company be liable for any damages and/or
                            losses incurred by Client.
                        </p>
                    </li>
                </ul>
                <p>3.2. Client hereby represents and warrants that:</p>
                <ul>
                    <li>
                        All terms and provisions of this Agreement have been
                        carefully read and understood by Client.
                    </li>
                    <li>
                        Client has not been coerced, or otherwise persuaded to
                        enter into this Agreement, nor has Client entered into
                        this Agreement based on any representation other than
                        what is included herein.
                    </li>
                    <li>base Client is at least 18 years of age.</li>
                    <li>
                        Client is legally permitted to perform Fixed Time Trades
                        in Client’s jurisdiction.
                    </li>
                    <li>
                        Client is neither citizen nor resident of a country
                        where local laws and regulations ban or limit Fixed Time
                        Trades.
                    </li>
                    <li>
                        Client is a natural person, legally capable citizen,
                        neither citizen nor resident of Australia, Austria,
                        Belarus, Belgium, Bulgaria, Canada, Croatia, Republic of
                        Cyprus, Czech Republic, Denmark, Estonia, Finland,
                        France, Germany, Greece, Hungary, Iceland, Iran,
                        Ireland, Israel, Italy, Latvia, Liechtenstein,
                        Lithuania, Luxembourg, Malta, Myanmar, Netherlands, New
                        Zealand, North Korea, Norway, Poland, Portugal, Puerto
                        Rico, Romania, Russia, Singapore, Slovakia, Slovenia,
                        South Sudan, Spain, Sudan, Sweden, Switzerland, UK,
                        Ukraine, the USA, Yemen.
                    </li>
                    <li>
                        Registration of the Account with the Company in Client’s
                        name does not violate any laws or regulations applicable
                        in a country of Client’s residence.
                    </li>
                    <li>
                        Client has been provided with a warning and understands
                        that Fixed Time Trades involve a significant risk of
                        loss, and that due to the volatile nature of trading
                        Client should not invest more than he/she can afford to
                        lose.
                    </li>
                    <li>
                        Client has the knowledge, experience, and understanding
                        of all the merits, risks, and restrictions associated
                        with Fixed Time Trades.
                    </li>
                    <li>
                        Client is acting solely on his/her own behalf and is not
                        acting for a purpose of a trade, business or profession.
                    </li>
                    <li>
                        All information Client has provided to the Company
                        during registration of Client’s Account is true and
                        accurate.
                    </li>
                    <li>
                        Should any information provided during the registration
                        process become invalid, Client shall immediately notify
                        the Company in writing about any such change.
                    </li>
                    <li>
                        Funds Client will use for trading belong to Client and
                        are free of any lien, charge, pledge or other
                        encumbrances; and do not represent the direct or
                        indirect proceeds of any criminal or illegal activities.
                    </li>
                    <li>
                        <p>
                            Client shall not give a third party, including
                            without limitation to any next of kin and/or to
                            members of his/her immediate family, access to
                            and/or manage the Account opened in his/her name.
                        </p>
                    </li>
                </ul>
                <h3>4. RIGHTS AND OBLIGATIONS OF THE PARTIES</h3>
                <p>4.1. Client has a right:</p>
                <ul>
                    <li>
                        to perform Fixed Time Trades using the ExpertOption
                        platform offered by the Company. to request and receive
                        from us any information regarding your Account.
                    </li>
                    <li>
                        to use funds available in his/her Account at any time at
                        Client’s sole discretion.
                    </li>
                    <li>
                        to make inquiries, give instructions and orders to the
                        Company regarding the execution of Fixed Time Trades as
                        long as these are submitted through Client’ Account.
                    </li>
                    <li>
                        in case of any disputes, to submit a complaint to the
                        Company in a formal email through the email address
                        specified on the Site.
                    </li>
                    <li>
                        <p>
                            to terminate this Agreement by providing a written
                            notice to the Company 3 (three) days before the
                            termination. Upon receipt of such notice, a hold
                            will be placed on Client’s Account to allow any then
                            pending transactions to clear. All funds appearing
                            on the balance of Client’s Account must be withdrawn
                            or otherwise transferred before closure of the
                            Account is finalized.
                        </p>
                    </li>
                </ul>
                <p>4.2. Client is obliged:</p>
                <ul>
                    <li>
                        to comply with the provisions of this Agreement when
                        using Services offered by the Company and/or performing
                        Transactions on the Site and/or through Mobile App.
                    </li>
                    <li>
                        to provide all documents requested by the Company for
                        KYC purposes.
                    </li>
                    <li>
                        to ensure that no other third party, including without
                        limitation to any next of kin and/or to members of
                        his/her immediate family, shall gain access to and/or
                        trade through the Account opened in Client’s name.
                    </li>
                    <li>
                        to take full responsibility for all transactions
                        performed through Client’s account; and to accept risk
                        of possible financial loss of Client or Company resulted
                        from unauthorized access to Client’s Account by the
                        third parties.
                    </li>
                    <li>
                        to assume full responsibility in relation to any
                        investment strategy or transaction, and for any
                        consequences brought by or from any transaction that
                        Client performs.
                    </li>
                    <li>
                        not to duplicate, reproduce, summarize, or otherwise
                        disclose the Company’s confidential information to any
                        person without prior express written consent of the
                        Company.
                    </li>
                    <li>
                        to immediately notify the Company within seven (7)
                        calendar days should any information provided during the
                        registration process become invalid or updated.
                    </li>
                    <li>
                        to register on the Site only one (1) Account. If the
                        Company discovers that Client has multiple Accounts
                        opened, the Company reserves the right to terminate this
                        Agreement, block all Client’s Accounts, and retain all
                        profits earned.
                    </li>
                    <li>
                        <p>
                            not to open, directly or indirectly, any new
                            accounts with the Company if Client’s current
                            account has been suspended or blocked by the
                            Company.
                        </p>
                    </li>
                </ul>
                <p>4.3. The Company has a right:</p>
                <ul>
                    <li>
                        to modify or update any provision of this Agreement at
                        any time for any reason and any such changes to this
                        Agreement will supersede and replace any previous
                        provisions of this Agreement effective immediately upon
                        posting on the Site.
                    </li>
                    <li>
                        to conduct “Know your customer” (KYC) and “Anti-money
                        laundering/Counter-terrorist Financing” (AML/CTF) checks
                        and procedures on Client in accordance with applicable
                        laws and regulations to prevent money laundering and/or
                        terrorism financing and any transaction or activity that
                        facilitates money laundering and/or terrorism financing.
                    </li>
                    <li>
                        to keep all information Client has provided to the
                        Company during registration of the Client’s Account for
                        at least 7 years as required by applicable laws and
                        regulations.
                    </li>
                    <li>
                        to place a hold on Client’s initial deposit for a period
                        of 180 days in case Client fails/refuses to provide
                        documents requested for KYC purposes. Under no
                        circumstances shall the Company be liable for any
                        damages and/or losses incurred by Client.
                    </li>
                    <li>
                        to change, add or set as default the return rate, profit
                        rate, the minimum and/or the maximum trade amount, the
                        possible expiration periods for one, several or all of
                        the assets. Company has the right to limit the number of
                        Fixed Time Trades placed during any period that appears
                        on the trading platform.
                    </li>
                    <li>
                        to revoke Client’s access to the Site at any time, where
                        the Company deems necessary.
                    </li>
                    <li>
                        to carry out additional checks and/or request additional
                        documentation from Client before the Company allows
                        Client to resume any activity through the Account, where
                        Client has not carried any activity and/or transactions
                        for a period of time, as determined within reason by the
                        Company.
                    </li>
                    <li>
                        to engage any third party for the provision of Services
                        in accordance with this Agreement, provided that such
                        third party providers fully assume the Company’s
                        obligations to keep all information received from Client
                        as strictly confidential.
                    </li>
                    <li>
                        to utilize, at its sole discretion, a third party to
                        hold Client’s funds and/or for purposes of receiving
                        payment execution services.
                    </li>
                    <li>
                        to automatically deduct a fixed dormant account fee in
                        the amount of 50USD/month from Client’s Account.
                    </li>
                    <li>
                        to reject and immediately return funds deposited if the
                        funds do not originate from a bank account owned by
                        Client.
                    </li>
                    <li>
                        to refuse execution of any instruction/order received
                        from Client without giving any explanations of the
                        reasons.
                    </li>
                    <li>
                        to set limits on the amounts that Client can withdraw on
                        a daily or other periodic basis.
                    </li>
                    <li>
                        to reject the withdrawal request if Client’s Account
                        does not have sufficient funds to satisfy Client’s
                        withdrawal request and to cover any applicable
                        withdrawal fees.
                    </li>
                    <li>
                        to deduct up to 20% commission from the withdrawal
                        amount if the trading turnover on Client's Account is
                        less than the amount deposited in order to eliminate the
                        possibility of online fraud and to cover transaction
                        costs.
                    </li>
                    <li>
                        to temporarily or permanently suspend Client’s access to
                        the Site and the Account, and/or terminate the
                        Agreement, and/or take any other actions as the Company
                        may see fit under the circumstances.
                    </li>
                    <li>
                        to debit Client’s Account any tax payment which may be
                        levied in relation to Client’s Transactions with the
                        Company.
                    </li>
                    <li>
                        to terminate the Agreement with immediate effect,
                        notwithstanding any other action, and freeze all funds
                        or assets in Client’s Account where:
                    </li>
                </ul>
                <ul>
                    <ul>
                        <li>
                            we believe, at our sole and absolute discretion,
                            that You have breached any provision of this
                            Agreement.
                        </li>
                        <li>
                            we believe, at our sole and absolute discretion,
                            that You engage in abusive behaviour.
                        </li>
                        <li>
                            we are unable to verify any documents and
                            information You provided.
                        </li>
                        <li>
                            we believe, at our sole and absolute discretion,
                            that Your actions may cause legal liability for You,
                            other Clients or the Company.
                        </li>
                        <li>You engage in illegal activities.</li>
                        <li>
                            we have reasonable grounds to suspect that You are
                            directly or indirectly using our services in
                            violation of Your applicable laws or regulations.
                        </li>
                        <li>
                            we are directed to do so by applicable laws and
                            regulations, law enforcement, regulatory authority
                            or court order.
                        </li>
                        <li>
                            we believe, at our sole and absolute discretion,
                            that there is another reason.
                        </li>
                    </ul>
                </ul>
                <ul>
                    <li>
                        to suspend and block Client’s account and to invalidate
                        all Transactions if Client provides false data during
                        the registration process.
                    </li>
                    <li>
                        to suspend or block Client’s account(s), invalidate
                        Transactions and seize funds from the balance of
                        Client’s account(s) if Client violates this Agreement.
                    </li>
                    <li>
                        <p>
                            to act at its sole and absolute discretion with
                            respect to matters not covered by this Agreement.
                        </p>
                    </li>
                </ul>
                <p>4.4. Account Closure Charges</p>
                <p>
                    The Company reserves the right to levy account closure
                    charges in the amount of USD10.00, or its equivalent in
                    another currency, if:
                </p>
                <ul>
                    <li>
                        Client’s Account is closed due to Client’s failure to
                        provide up-to-date and accurate information and
                        documents required for Client identification and
                        verification purposes.
                    </li>
                    <li>
                        Client’s Account is closed due to any violation or
                        attempted violation of any provision of this Agreement
                        by Client.
                    </li>
                    <li>
                        <p>
                            Client’s Account is closed following Client’s
                            written notice to terminate this Agreement.
                        </p>
                    </li>
                </ul>
                <p>4.5. The Company is obliged:</p>
                <ul>
                    <li>
                        to act honestly, fairly, and professionally when
                        providing Services to Clients.
                    </li>
                    <li>to provide access to the Site and Client’s Account.</li>
                    <li>
                        <p>
                            to process Client’s withdrawal request within three
                            (3) business days, provided that Client has
                            sufficient funds in Client’s Account to satisfy
                            Client’s withdrawal request and to cover any
                            applicable withdrawal fees.
                        </p>
                    </li>
                </ul>
                <h3>5. GOVERNING LAW AND DISPUTE RESOLUTION</h3>
                <p>
                    5.1. This Agreement shall be governed by and interpreted in
                    accordance with the laws of the Saint Vincent and the
                    Grenadines.
                </p>
                <p>
                    5.2. If any dispute, controversy or claim arises out of this
                    Agreement between Client and the Company, Client is strongly
                    encouraged to first contact the Company directly to seek
                    amicable resolution.
                </p>
                <p>
                    5.3. Disputes, controversies or claims arising out of or
                    relating to this Agreement, which have not been amicably
                    resolved between Client and the Company within thirty (30)
                    calendar days, shall be submitted to the non-exclusive
                    jurisdictions of the courts of the Saint Vincent and the
                    Grenadines for the final resolution.
                </p>
                <h3>6. INDEMNIFICATION AND LIMITATION OF LIABILITY</h3>
                <p>
                    6.1. Client agrees to defend, indemnify and hold harmless
                    the Company, its affiliates and respective directors,
                    officers, employees and agents from and against all claims
                    and expenses arising out of the use (misuse) of the Site,
                    Mobile Apps and Client’s Account.
                </p>
                <p>
                    6.2. Client acknowledges and accepts that the use of our
                    services or any systems owned or used by the Company is
                    entirely at Client’s own risk.
                </p>
                <p>
                    6.3. Client acknowledges and accepts that Client will enter
                    into all and any transactions with the Company at his/her
                    own risk, and the Company assumes no liability for any loss
                    whatsoever as a result of Client’s trading activity with us.
                </p>
                <p>
                    6.4. Notwithstanding any other provision in the Agreement,
                    the Company shall not be liable for any loss or damage
                    caused by the Company or its employees or representatives in
                    the event of:
                </p>
                <ul>
                    <li>
                        negligence, fraud, breach of the Agreement, breach of
                        any law and/or any other act and/or omission by Client;
                    </li>
                    <li>
                        unavailability of the Site, other than in instances of
                        wrongdoing by the Company;
                    </li>
                    <li>
                        Client being unable to access Client’s Account and/or
                        the Site or any other system, or any delay Client may
                        suffer when attempting to contact any of our staff,
                        unless this is due to wrongdoing by the Company;
                    </li>
                    <li>
                        the Company taking measures to ensure compliance with
                        any applicable law or regulation, including where we are
                        precluded from processing any instruction from Client,
                        which may result in the Company breaching the applicable
                        law;
                    </li>
                    <li>
                        <p>
                            such loss or damage, which is not a reasonably
                            foreseeable result of any such breach.
                        </p>
                    </li>
                </ul>
                <p>
                    6.5. The Company shall not be responsible for any delays,
                    delivery failures, or any loss or damage which results from
                    the transmission of information over any network, including
                    but not limited to the Internet.
                </p>
                <p>
                    6.6. Access to the Site and Mobile Apps is provided on an
                    "as is" basis without warranties of any kind, either express
                    or implied, including, but not limited to, warranties of
                    title or implied warranties of merchantability or fitness
                    for a particular purpose, other than those warranties which
                    are implied by and incapable of exclusion, restriction or
                    modification under the laws applicable to this Agreement.
                </p>
                <p>
                    6.7. Neither the Company nor its directors, officers,
                    employees, agents and representative shall be liable for any
                    losses, damages, costs or expenses incurred or suffered by
                    Client resulting or arising from any act or omission made
                    under or in relation to or in connection with the provision
                    of the Services or under this Agreement unless arising
                    directly from the Company’s or Client’s negligence, breach
                    of any duty the Company may owe Client under the regulatory
                    system, bad faith, wilful default or fraud.
                </p>
                <p>
                    6.8. Neither the Company nor its directors, officers,
                    employees, agents and representative shall be liable in
                    contract, tort, negligence, breach of statutory duty or
                    otherwise for any loss, damage, costs or expenses of any
                    nature whatsoever incurred or suffered by Client of an
                    indirect or consequential nature including without
                    limitation any loss of opportunity, loss of profit, loss of
                    business or loss of goodwill.
                </p>
                <p>
                    6.9. Without limitation, the Company does not accept
                    liability for any adverse tax implications of any Client’s
                    Transaction. The Company does not and shall not provide any
                    tax advice, and the Company shall not at any time be deemed
                    to be under any duty to provide such advice.
                </p>
                <p>
                    6.10. Neither the Company nor its directors, officers,
                    employees, agents and representative shall be liable for any
                    delay or change in market conditions before any particular
                    Transaction is carried out.
                </p>
                <p>
                    6.11. Neither the Company nor its directors, officers,
                    employees, agents and representatives shall be liable to
                    Client for the solvency, acts or omission of any third party
                    appointed for the purposes of the provision of Services
                    under this Agreement.
                </p>
                <p>
                    6.12. Neither the Company nor its directors, officers,
                    employees, agents and representatives shall be liable for
                    any loss or damage caused by Client’s reliance on
                    information obtained through the Site. It is the
                    responsibility of Client to evaluate the accuracy,
                    completeness or usefulness of any information, opinion,
                    report or other content available through the Site. Clients
                    should seek the advice of professionals, as appropriate,
                    regarding the evaluation of any specific information,
                    opinion, report or other content.
                </p>
                <p>
                    6.13. Neither the Company nor its directors, officers,
                    employees, agents and representatives shall be liable for
                    any losses or damages (whether direct or indirect) caused by
                    changes in regulatory and compliance requirements of the
                    applicable laws and regulations.
                </p>
                <p>
                    6.14. Neither the Company nor its directors, officers,
                    employees, agents and representatives shall be liable for
                    any loss that Client may incur as a result of someone else
                    using Client’s login credentials or Account, either with or
                    without Client’s knowledge. However, Client shall be held
                    liable for losses incurred by the Company or another party
                    due to someone else using Client’s Account or login
                    credentials.
                </p>
                <p>
                    6.15. The information and material contained on the Site is
                    for information purposes only and does not constitute any
                    kind of advice. Such information and material may be
                    incorrect or out of date and should not be considered as a
                    definitive or complete statement of fact. Client should
                    check any information and material on the Site and use
                    his/her own judgement before doing or not doing anything on
                    the basis of such information or material.
                </p>
                <p>
                    6.16. The Company makes no representations or warranties
                    with respect to the Site or its content. All warranties
                    (express or implied) (including without limitation the
                    implied warranties of satisfactory quality and fitness for
                    purpose relating to the Site and/or its content) are hereby
                    excluded to the fullest extent permitted by law. No
                    representations or warranties are given as to the accuracy
                    or completeness of the information or material provided on
                    the Site or any website or webpage to which it is linked.
                </p>
                <p>
                    6.17. Neither the Company nor its directors, officers,
                    employees, agents and representative shall be liable for:
                </p>
                <ul>
                    <li>loss of or corruption to data; or</li>
                    <li>loss of profit; or</li>
                    <li>base loss of anticipated revenue; or</li>
                    <li>base loss of business; or</li>
                    <li>base loss of opportunity; or</li>
                    <li>
                        base adverse effect on reputation and/or goodwill; or
                    </li>
                    <li>
                        <p>
                            any indirect or consequential loss or damage arising
                            as a result of regulatory fines and damage to
                            property.
                        </p>
                    </li>
                </ul>
                <p>
                    6.18. The Company shall not be liable to Client if Client
                    cannot access the Site properly or at all (wholly or partly)
                    or if some of its features are unavailable to Client due to
                    events outside the Company’s control (which shall include
                    without limitation the performance of any internet service
                    provider, the performance of any internet browser or
                    limitations of the device Client uses to access the Site).
                </p>
                <p>
                    6.19. The Company shall not be liable to Client for any loss
                    or inconvenience suffered due to the unavailability or
                    withdrawal of any content, material or other information
                    from the Site.
                </p>
                <p>
                    6.20. Under no circumstances shall the Company be liable
                    for:
                </p>
                <ul>
                    <li>
                        indirect, incidental or consequential loss, including
                        but not limited to financial or commercial losses, loss
                        of profit, increase of general expenses, missed
                        opportunities, diminished goodwill, damages resulting
                        from business stoppage, damages resulting from claims
                        against Client, loss of expected profit, loss of
                        capital, loss of information, loss of advantages;
                    </li>
                    <li>
                        damages resulting from error or negligence of Client;
                    </li>
                    <li>
                        compensation of any direct and indirect damages caused
                        by the use of Services;
                    </li>
                    <li>
                        compensation of any direct and indirect damages caused
                        in whole or in part by software or hardware supplied or
                        created by third parties;and
                    </li>
                    <li>
                        <p>
                            all claims of third parties brought against Client.
                        </p>
                    </li>
                </ul>
                <h3>7. MISCELLANEOUS</h3>
                <h3>Language</h3>
                <p>
                    7.1. The Company’s official language is the English
                    language. The provision of any information, including
                    marketing material, any translated version of this Agreement
                    and/or any other communication, in a language other than our
                    official language, is provided solely for convenience
                    purposes and the legally binding version shall be the
                    English language version of such documentation. In the event
                    of a dispute, the English version shall prevail.
                </p>
                <h3>Tax Obligations</h3>
                <p>
                    7.2. Proceeds from Fixed Time Trades may be subject to tax
                    depending on the country where Client resides. Client should
                    seek independent tax advice if Client is unsure on how this
                    may affect him/her, as the Company does not provide any tax
                    advice.
                </p>
                <p>
                    7.3. Client is solely responsible for determining what, if
                    any, taxes apply as well as for collecting, reporting and
                    remitting any applicable taxes to a relevant tax authority.
                </p>
                <p>
                    7.4. Client understands that tax laws are subject to change
                    and in the event they do the Company reserves the right to
                    debit Client’s Account any tax payment which may be levied
                    in relation to Client’s transactions with the Company.
                </p>
                <h3>Single Agreement</h3>
                <p>
                    7.5. This Agreement, the terms agreed between the Company
                    and Client with respect to each Transaction, and all
                    amendments to any of such items shall together constitute a
                    single agreement between the Company and Client. Both the
                    Company and Client acknowledge that all Transactions are
                    entered into in reliance upon such fact, it being understood
                    that the Company and Client would not otherwise enter into
                    any Transaction.
                </p>
                <h3>Recording of Calls</h3>
                <p>
                    7.6. The Company may record phone conversations without use
                    of a warning tone to ensure that the material terms of the
                    Services, and any other material information relating to the
                    Services are promptly and accurately recorded. Such records
                    will be the Company’s sole property and shall be accepted by
                    Client as evidence of orders or instructions given by
                    Client.
                </p>
                <h3>Company’s Records</h3>
                <p>
                    7.7. The Company’s records shall be used as evidence of all
                    Client’s dealings with the Company in connection with
                    Services. Hereby Client confirms that he/she shall not
                    object to having the Company’s records presented as evidence
                    in any legal proceedings because such records are not
                    originals, are not in writing nor are they documents
                    produced by a computer. Client shall not rely on the Company
                    to comply with Client’s record keeping obligations, although
                    records may be made available to Client upon request at
                    Company’s absolute discretion.
                </p>
                <h3>Rights and remedies</h3>
                <p>
                    7.8. The rights and remedies provided under this Agreement
                    are cumulative and not exclusive of those provided by law.
                    The Company shall be under no obligation to exercise any
                    right or remedy either at all or in a manner or at a time
                    beneficial to Client. No failure by the Company to exercise
                    or delay by the Company in exercising any of the Company’s
                    rights under this Agreement (including any Transaction) or
                    otherwise shall operate as a waiver of those or any other
                    rights or remedies. No single or partial exercise of a right
                    or remedy shall prevent further exercise of that right or
                    remedy or the exercise of another right or remedy.
                </p>
                <h3>Set-off</h3>
                <p>
                    7.9. Without prejudice to any other rights to which we may
                    be entitled, the Company may at any time and without any
                    prior notice to Client write off any amount (whether actual
                    or contingent, present or future) owed by Client to the
                    Company against any amount (whether actual or contingent,
                    present or future) owed by the Company to Client. For these
                    purposes, the Company may ascribe a commercially reasonable
                    value to any amount which is contingent or which for any
                    other reason is unascertained.
                </p>
                <h3>Severability</h3>
                <p>
                    7.10. Any part, provision, representation or warranty of
                    this Agreement which is prohibited or which is held to be
                    void or unenforceable shall be ineffective to the extent of
                    such prohibition or unenforceability without invalidating
                    the remaining provisions hereof. Any part, provision,
                    representation or warranty of this Agreement which is
                    prohibited or unenforceable or is held to be void or
                    unenforceable in any jurisdiction shall be ineffective, as
                    to such jurisdiction, to the extent of such prohibition or
                    unenforceability without invalidating the remaining
                    provisions hereof, and any such prohibition or
                    unenforceability in any jurisdiction shall not invalidate or
                    render unenforceable such provision in any other
                    jurisdiction. To the extent permitted by applicable law, the
                    parties hereto waive any provision of law which prohibits or
                    renders void or unenforceable any provision hereof.
                </p>
                <h3>Prohibited Trading</h3>
                <p>
                    7.11. The Company prohibits current and former, part-time
                    and full-time, employees, current and former business
                    associates, and affiliates of the Company or any of its
                    affiliated entities (“relevant persons”) to register an
                    account on the Site and/or become a client of the Company
                    (either directly or indirectly) without the Company’s prior
                    written consent.
                </p>
                <p>
                    7.12. Should the Company consider that any of relevant
                    persons is trading with the Company without the Company’s
                    prior written consent, the Company shall consider all the
                    trading to be abusive and/or improper trading. In such
                    circumstances the relevant person’s account and all open
                    positions shall be closed immediately and any available
                    balance excluding the initial deposit shall be written off.
                </p>
                <h3>One-Click Trading Disclaimer</h3>
                <p>
                    7.13. Client hereby confirms that Client’s current version
                    of the terminal enables Client to choose between the
                    following modes for order submission. Client agrees that
                    he/she will be bound by the terms and conditions specified
                    herein with respect to each such mode:
                </p>
                <ul>
                    <li>
                        The Default mode for order submission is a two-step
                        process. Using the Default mode, Client first invokes a
                        new order window. Then Client needs to select an
                        appropriate order type, its parameters and confirm order
                        submission by clicking either Buy, Sell, Place, Modify
                        or Close buttons depending on a particular order type
                        selected and Client’s trading intentions. Using the
                        Default mode, Client’s order will not be submitted until
                        Client has completed both of the aforementioned steps.
                    </li>
                    <li>
                        The One Click Trading mode for order submission
                        (“One-click trading”) is a one-step process. Using the
                        One Click Trading mode, Client’s order will be submitted
                        when Client:
                    </li>
                </ul>
                <ul>
                    <ul>
                        <li>
                            single-clicks either bid (SELL) or ask (BUY) buttons
                            either on the Trading tab in the Market Watch
                            window, or on the One Click Trading panel of a
                            chart;
                        </li>
                        <li>
                            <p>
                                closes pending orders or deletes stop levels on
                                the Trade tab of the Terminal window.
                            </p>
                        </li>
                    </ul>
                </ul>
                <p>
                    7.14. THERE WILL BE NO FURTHER CONFIRMATIONS FOR CLIENT TO
                    CLICK. CLIENT WILL NOT BE ABLE TO WITHDRAW OR CHANGE
                    CLIENT’S ORDER ONCE CLIENT CLICKS. UNDER NORMAL MARKET
                    CONDITIONS AND SYSTEM PERFORMANCE, A MARKET ORDER WILL BE
                    PROMPTLY FILLED AFTER SUBMISSION AND CLIENT WILL HAVE
                    ENTERED INTO A BINDING TRANSACTION.
                </p>
                <p>
                    7.15. Client can activate or deactivate One Click Trading
                    mode on the Trade tab window of the terminal.
                </p>
                <p>
                    7.16. By selecting the One Click Trading mode, Client
                    understands that Client’s orders will be submitted by
                    clicking the bid or ask rate button or in any other way
                    described above, without any further order confirmation.
                    Client agrees to accept all risks associated with the use of
                    the order submission mode Client has chosen, including,
                    without limitation, the risk of errors, omissions or
                    mistakes made in submitting any order.
                </p>
                <p>
                    7.17. Client agrees to fully indemnify and hold harmless the
                    Company from any and all losses, costs and expenses that
                    Client may suffer as a result of any such errors, omissions
                    or mistakes by Client, Client’s trading manager or any other
                    person trading on Client behalf.
                </p>
                <h3>8. TERM AND TERMINATION</h3>
                <p>
                    8.1. This Agreement shall come into force on the date of its
                    acceptance by Client.
                </p>
                <p>
                    8.2. This Agreement does not have an ending date but will
                    remain in force until one of the Parties expresses a desire
                    to terminate it.
                </p>
                <p>
                    8.3. Client has the right to terminate this Agreement by
                    providing a written notice to the Company 3 (three) days
                    before the termination. Upon receipt of such notice, a hold
                    will be placed on Client’s Account to allow any then pending
                    transactions to clear.
                </p>
                <p>
                    8.4. All funds appearing on the balance of Client’s Account
                    must be withdrawn or otherwise transferred before closure of
                    Client’s Account is finalized.
                </p>
                <p>
                    8.5. The Company shall terminate the Agreement with
                    immediate effect without any prior notice to Client,
                    notwithstanding any other action, invalidate Transactions
                    and seize funds from the balance of Client’s account(s)
                    where:
                </p>
                <ul>
                    <li>
                        the Company believes, at its sole and absolute
                        discretion, that Client has breached any provision of
                        this Agreement.
                    </li>
                    <li>
                        the Company believes, at its sole and absolute
                        discretion, that Client engages in abusive behaviour.
                    </li>
                    <li>
                        the Company is unable to verify any documents and
                        information provided by Client.
                    </li>
                    <li>
                        the Company believes, at its sole and absolute
                        discretion, that Client’s actions may cause legal
                        liability for Client, other Clients or the Company.
                    </li>
                    <li>base Client engages in illegal activities.</li>
                    <li>
                        the Company has reasonable grounds to suspect that
                        Client is directly or indirectly using the Company’s
                        services in violation of Client’s applicable laws or
                        regulations.
                    </li>
                    <li>
                        the Company has reasonable grounds to suspect that
                        Client’s account is used for opening buy/sell positions
                        in the financial instrument while a position in the same
                        financial instrument in the opposite direction is opened
                        from another Client’s account.
                    </li>
                    <li>
                        the Company has reasonable grounds to suspect that
                        Client has opened, directly or indirectly, new accounts
                        with the Company after having Client’s current account
                        frozen or blocked by the Company.
                    </li>
                    <li>
                        the Company is directed to do so by applicable laws and
                        regulations, law enforcement, regulatory authority or
                        court order.
                    </li>
                    <li>
                        <p>
                            the Company believes, at its sole and absolute
                            discretion, that there is another reason.
                        </p>
                    </li>
                </ul>
                <p>
                    8.6. Upon termination, any fees due or any expenses incurred
                    by the Company as a result of the termination of this
                    Agreement should be settled immediately. Unless otherwise
                    agreed in writing, any amount due or outstanding will be
                    directly deducted from Client’s Account.
                </p>
                <h3>Annex 1</h3>
                <p>to the Terms &amp; Conditions</p>
                <h4>Technical Terms and Conditions</h4>
                <h4>
                    1. Processing Client’s requests and orders for Transaction
                    execution.
                </h4>
                <p>
                    1.1. Processing Client’s requests and orders have the
                    following structure:
                </p>
                <ul>
                    <li>
                        after Client makes a request or order, the request is
                        tested for its correctness in the Site platform;
                    </li>
                    <li>
                        the Site platform sends Client’s request or order to the
                        server;
                    </li>
                    <li>
                        once Client’s request or order is received by the server
                        it is tested for correctness;
                    </li>
                    <li>
                        <p>
                            the server, after processing Client’s order or
                            request, sends the results to the Site platform. If
                            connection between the Site platform and the server
                            is routed, the Site platform will receive the
                            results of Client’s order or request and the process
                            will be done.
                        </p>
                    </li>
                </ul>
                <p>
                    1.2. The processing time may vary and depends on the quality
                    of the communication between the Site platform and the
                    server, as well as on the market conditions. In normal
                    market conditions the processing time usually varies between
                    1-4 seconds. In some cases and market conditions that differ
                    from normal, the processing time can be longer than 4
                    seconds.
                </p>
                <p>
                    1.3. The server may refuse Client’s request or order in the
                    following cases:
                </p>
                <ul>
                    <li>
                        if Client sends request or order before the first quote
                        in the Site platform on the market opening;
                    </li>
                    <li>
                        if there are insufficient funds on Client’s Account for
                        opening a new position;
                    </li>
                    <li>
                        if Client sends request or order before opening the
                        trading session;
                    </li>
                    <li>base when market conditions differ from normal;</li>
                    <li>
                        <p>
                            in case of technical errors or as a result of any
                            money management limitations (minimum or maximum
                            rates, etc).
                        </p>
                    </li>
                </ul>
                <p>
                    1.4. When working with the Site platform Client may only use
                    one browser tab. If Client uses multiple browser tabs,
                    information and results of Transactions can be adjusted
                    and/or abolished.
                </p>
                <p>
                    1.5. Client acknowledges and accepts that information about
                    Client’s Account balance reflected on the Site platform, and
                    also about Client’s Transactions and their results, is
                    complete and indisputable. Client has no right to demand
                    from the Company to change the information reflected on the
                    Site platform, and has no right to demand from the Company
                    any compensation of any funds which are expected by Client
                    as a result of any Transactions which are not reflected by
                    the Company on the Site.
                </p>
                <h4>2. Quotes</h4>
                <p>
                    2.1. Client acknowledges and accepts that the only reliable
                    source of quote flow information is the main server for
                    Clients. The information contained in quotes on the Site
                    platform cannot serve as a reliable source of information
                    about the real quotes flow, as in the case of unstable
                    connection between the Site platform and the server part of
                    the quotes may not reach the Site platform.
                </p>
                <p>
                    2.2. The graphs displayed on the Site platform are
                    indicative. Thus, the Company does not guarantee that the
                    order will be executed at the same prices specified in the
                    Site platform at the time of Client’s Transactions.
                </p>
                <p>
                    2.3. The price displayed on the trading platform is formed
                    by the formula (Bid + Ask)/ 2.
                </p>
                <p>2.4. Non-Market quotation</p>
                <p>
                    2.4.1. Non-Market quotation — the price specified in the
                    Site platform without the corresponding price on the market
                    at a given time.
                </p>
                <p>
                    2.4.2. If the Company has executed Client’s request or order
                    at non-market quote, Company is charged with the
                    responsibility for correction of financial transactions
                    between erroneous positions and closing of the actual market
                    price, corresponding to the time of closing of the
                    non-market quotation.
                </p>
                <p>
                    2.4.3. In the event that Client’s request or order to the
                    opening position was executed at a non-market quote, the
                    Company reserves the right to cancel the financial result
                    for such a position.
                </p>
                <h4>3. Processing of Client’s orders for position opening</h4>
                <p>
                    3.1. If the amount of available on the Client’s Account
                    funds is sufficient to open a position — the position will
                    be opened.
                </p>
                <p>
                    3.2. If the size of the available on the Client’s Account
                    funds is insufficient to open a position — the position
                    won’t be opened.
                </p>
                <p>
                    3.3. The Client’s order to open a position is deemed as
                    proceed, and the position is opened only after the
                    corresponding entry in the server log file. Each new
                    position is assigned with a serial number.
                </p>
                <p>
                    3.4. Closing trading position occurs at the current price.
                </p>
                <p>
                    3.5. Company reserves the right, in its sole discretion, to
                    place limits on the turnover and/or number of Client's
                    trades in financial instruments for the last 24 hours based
                    on the current market conditions and liquidity. In case
                    Client exceeds the limit on the turnover and/or number of
                    trades, Company reserves the right to reject/cancel Client's
                    orders/trades, without giving rise to any claim against
                    Company.
                </p>
                <h4>4. OTC Assets</h4>
                <p>
                    4.1. OTC (over-the-counter) assets are any assets that are
                    traded on the OTC market.
                </p>
                <p>
                    4.2. Prices of OTC assets are formed by the Company based on
                    the information received from regulated markets, and/or OTC
                    markets, and/ or other sources.
                </p>
                <p>
                    4.3. Client acknowledges and confirms that he/she
                    understands the nature of OTC transactions and fully
                    understands all risks associated with OTC trading. By
                    placing an order with the Company for any OTC asset, the
                    Client confirms that he/she independently, based on his/her
                    knowledge and experience or an advice obtained from an
                    independent professional financial, investment, legal,
                    regulatory, tax and other advisor, determines if such a
                    transaction is suitable and appropriate for him/her.
                </p>
                <p>
                    4.4. Client acknowledges and accepts that, by placing orders
                    with the Company for any OTC asset, the Company’s trading
                    platform and server shall be the only reliable source of
                    quotes for OTC assets offered by the Company.
                </p>
                <h4>5. Fraud</h4>
                <p>5.1. If the Company discovers fraud schemes such as:</p>
                <p>
                    5.1.1. Fraud associated with credit card transactions and
                    any other ways to remit a balance of credit cards that does
                    not belong to Client;
                </p>
                <p>
                    5.1.2. Fraud associated with the use of software for false
                    trading results;
                </p>
                <p>
                    5.1.3. Fraud associated with errors and system failures for
                    false trading results;
                </p>
                <p>
                    5.1.4. Fraud associated with opening positions in the same
                    financial instrument but in the opposite directions
                    (buy/sell) from different Clients’ accounts.
                </p>
                <p>
                    Client’s Account will be blocked by the Company without the
                    possibility of performing further Transactions through
                    Client’s Account and all payments to Client will be
                    cancelled. The Company reserves the right to inform and
                    involve law enforcement authorities for investigation if any
                    fraudulent methods to obtain payment will be used by Client.
                </p>
                <p>
                    5.2. Any using an unfair advantage or influence (commonly
                    known as cheating), including a decompilation, reverse
                    engineering, any implantations to the client—server
                    relationship, exploitation of bugs, loopholes or errors in
                    software, any hack, database security violation, the use of
                    automated players (also known as ’bots’); or the
                    exploitation of an ’error’ are strictly prohibited by the
                    Company. If such using will be revealed by the Company
                    Client’s Account will be blocked without any prior notice.
                    Client will not be able to use Services anymore, all
                    payments to Client will be cancelled and any Client’s fund
                    and deposit will be seized.
                </p>
                <h4>6. Description in Client’s bank statement.</h4>
                <p>
                    6.1. All transactions carried out by Client with debit or
                    credit card displayed in the bank statement with a note:
                    https://www.1xprime.com/, EXPERTOPTION or EXPERT.
                </p>
            </noindex>
        </div>
    );
}

export default Terms;
