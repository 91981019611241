import en from "./locales/en.json";
import zh from "./locales/zh.json";
import vi from "./locales/vi.json";

const resources = {
    en,
    zh,
    vi,
};

export default resources;
