import { useTranslation } from "react-i18next";

function Footer() {
    const { t } = useTranslation();

    return (
        <div className="w-full px-8 lg:px-[120px]">
            {/* footer content */}
            <div className="mx-auto flex w-full max-w-[1200px] flex-col items-center gap-6 py-10 lg:flex-row lg:items-start lg:justify-between lg:pb-20 lg:pt-16">
                {/* logo */}
                <img
                    src="assets/imgs/utils/logo/logo-col.png"
                    className="w-[100px] lg:hidden"
                />
                <img
                    src="assets/imgs/utils/logo/logo-row-text.png"
                    className="hidden w-[180px] lg:block"
                />
                {/* main content */}
                <div className="flex flex-col items-center gap-8 lg:flex-row lg:items-start lg:gap-[60px]">
                    {/* trading platform */}
                    <div className="flex flex-col items-center gap-4 lg:order-2  lg:items-start">
                        <span className="text-xl font-bold text-[#fff]">
                            {t("components.footer.titleText")}
                        </span>
                        <a href="https://app.1xprime.com" target="_blank">
                            <img
                                src="assets/imgs/components/footer/platform.png"
                                className="w-[160px]"
                            />
                        </a>
                    </div>
                    {/* about us */}
                    <div className="flex flex-col items-center gap-4 lg:order-1 lg:items-start">
                        <span className="text-xl font-bold text-[#fff]">
                            {t("components.footer.aboutUs")}
                        </span>
                        <a
                            href="/aboutus"
                            className="tex-center text-sm text-[#9cb3bf]"
                        >
                            {t("components.footer.aboutPrimeTrade")}
                        </a>
                        <a
                            href="/policy/privacy-policy"
                            className="tex-center text-sm text-[#9cb3bf]"
                        >
                            {t("components.footer.privacyPolicy")}
                        </a>
                        <a
                            href="/policy/terms"
                            className="tex-center text-sm text-[#9cb3bf]"
                        >
                            {t("components.footer.terms")}
                        </a>
                        {/* <a
                            href=""
                            className="tex-center text-sm text-[#9cb3bf]"
                        >
                            Client Funds Security
                        </a> */}
                        <a
                            href="/support"
                            className="tex-center text-sm text-[#9cb3bf]"
                        >
                            {t("components.footer.contactUs")}
                        </a>
                        {/* <a
                            href=""
                            className="tex-center text-sm text-[#9cb3bf]"
                        >
                            Help Center
                        </a> */}
                    </div>
                    {/* social */}
                    <div className="flex flex-col items-center gap-4 lg:order-3 lg:items-start">
                        <span className="text-xl font-bold text-[#fff]">
                            {t("components.footer.social")}
                        </span>
                        <div className="flex w-full items-center justify-center gap-6">
                            {/* <img
                                src="assets/imgs/utils/social/twitter.svg"
                                className="w-8"
                            />
                            <img
                                src="assets/imgs/utils/social/youtube.svg"
                                className="w-8"
                            /> */}
                            <a href="https://t.me/trade1xprime" target="_blank">
                                <img
                                    src="assets/imgs/utils/social/telegram.svg"
                                    className="w-8"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* footer lower content */}
            <div className="mx-auto w-full max-w-[1200px] py-10 lg:pb-20 lg:pt-16">
                <p className="whitespace-pre-line p-0 text-center text-sm text-[#9cb3bf] lg:text-left">
                    {t("components.footer.termsText")}{" "}
                    <a
                        target="_blank"
                        href="https://www.nfa.futures.org/BasicNet/basic-profile.aspx?nfaid=D%2Fl5e8EA0BU%3D"
                    >
                        0562772
                    </a>
                    .
                </p>
            </div>
        </div>
    );
}

export default Footer;
