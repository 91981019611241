import { useTranslation } from "react-i18next";

function Section1() {
    const { t } = useTranslation();

    return (
        // wrapper
        <div className="relative w-full overflow-hidden bg-[#27283E] pt-16">
            {/* bg */}
            <img
                src="assets/imgs/views/market/section1/section1-bg.svg"
                alt=""
                className="absolute left-0 top-0 z-0 h-full w-full object-cover object-left-top"
            />
            <div className="relative z-[1] w-full px-8 lg:px-[120px]">
                {/* section content */}
                <div className="mx-auto flex w-full max-w-[1200px] flex-col items-center gap-6 py-16 lg:gap-10 lg:py-[120px]">
                    {/* text group */}
                    <div className="flex max-w-[800px] flex-col items-center gap-4 lg:gap-6">
                        {/* title */}
                        <span className="whitespace-nowrap text-center text-5xl font-bold text-[#fff] lg:text-[72px]">
                            {t("market.section1.titleText")}
                        </span>
                        {/* description */}
                        <span className="text-center text-base text-[#93b3bf]">
                            {t("market.section1.description")}
                        </span>
                    </div>
                    {/* trade button */}
                    <a href="https://app.1xprime.com" target="_blank">
                        <button className="h-14 rounded-2xl bg-[#0066ff] px-16 text-center text-base font-bold text-[#e5f0f4]">
                            {t("market.section1.tradeNowBtnText")}
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Section1;
