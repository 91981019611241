import { useTranslation } from "react-i18next";

function Section3() {
    const { t } = useTranslation();

    return (
        <div className="w-full bg-[#fff] px-8 lg:px-[120px]">
            {/* section content */}
            <div className="mx-auto flex w-full max-w-[1200px] flex-col gap-6 pt-16 lg:flex-row lg:items-end lg:justify-between lg:gap-20 lg:pt-0">
                {/* main content */}
                <div className="flex flex-col gap-8 lg:my-auto lg:py-10">
                    {/* title */}
                    <p className="w-full p-0 text-[40px] font-bold text-[#151d24] lg:text-[56px]">
                        {t("home.section4.titleText_prefix")}{" "}
                        <span className="text-nowrap">
                            {t("home.section4.easyToUse")}
                        </span>{" "}
                        {t("home.section4.titleText_suffix")}
                    </p>
                    {/* description */}
                    <span className="w-full whitespace-pre-line text-base text-[#62737f]">
                        {t("home.section4.descriptionText")}
                    </span>
                </div>
                {/* phone image */}
                <img
                    src="assets/imgs/views/home/section4/phone.png"
                    alt=""
                    className="w-full object-contain lg:w-[40%] lg:translate-x-0"
                />
            </div>
        </div>
    );
}

export default Section3;
