import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./views/home/Home";
import Trade from "./views/trade/Trade";
import Market from "./views/market/Market";
import AboutUs from "./views/aboutUs/AboutUs";
import Support from "./views/support/Support";
import Policy from "./views/policy/Policy";

function App() {
    // const [user, setUser] = useState(null);

    useEffect(() => { 
 
        let scriptUrl = 'https://primetrade.ladesk.com/scripts/track.js'; 
        let node = document.createElement('script'); 
        node.src = scriptUrl; 
        node.id = 'la_x2s6df8d'; 
        node.type = 'text/javascript'; 
        node.async = true; 
        node.charset = 'utf-8'; 
        node.onload = function(e) { 
          window.LiveAgent.createButton('1qqusxme', document.getElementById("chatButton")); 
        }; 
        document.getElementsByTagName('head')[0].appendChild(node); 
    }, []);

    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/trading" element={<Trade />}></Route>
                <Route path="/markets" element={<Market />}></Route>
                <Route path="/aboutus" element={<AboutUs />}></Route>
                <Route path="/support" element={<Support />}></Route>
                <Route path="/policy/:content" element={<Policy />}></Route>
            </Routes>
            <Footer />
            <div id="chatButton"></div>
        </Router>
    );
}

export default App;
