import { useNavigate, useParams } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";

function Policy() {
    let { content } = useParams();

    return (
        <div className="w-full px-8 py-10 lg:px-[120px] lg:py-[100px]">
            {content === "privacy-policy" && <PrivacyPolicy />}
            {content === "terms" && <Terms />}
        </div>
    );
}

export default Policy;
