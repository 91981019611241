import { useTranslation } from "react-i18next";

function Section3() {
    const { t } = useTranslation();

    return (
        <div className="w-full bg-gradient-to-tr from-[#272F51] to-[#0C1124] px-8 lg:px-[120px]">
            <div className="mx-auto flex w-full max-w-[1200px] flex-col gap-6 pt-16 lg:flex-row lg:items-center lg:gap-20 lg:pt-0">
                {/* main content */}
                <div className="flex w-full flex-col gap-8 lg:order-2 lg:my-auto lg:py-10">
                    <div className="flex w-full flex-col gap-4">
                        <span className="text-[40px] font-bold text-[#fff] lg:text-[56px]">
                            {t("home.section3.titleText_prefix")}
                        </span>
                        <span className="text-[40px] font-bold text-[#0066ff] lg:text-[56px]">
                            {t("home.section3.titleText_suffix")}
                        </span>
                    </div>
                    <span className="w-full text-base text-[#e5f0f4] lg:order-1">
                        {t("home.section3.descriptionText")}
                    </span>
                </div>
                {/* description image */}
                <img
                    src="assets/imgs/views/home/section3/description.png"
                    alt=""
                    className="w-full lg:w-1/2"
                />
            </div>
        </div>
    );
}

export default Section3;
