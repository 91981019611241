function Section1() {
    return (
        // wrapper
        <div className="relative w-full overflow-hidden bg-[#27283E] pt-16">
            {/* bg */}
            <img
                src="assets/imgs/views/aboutUs/section1/section1-bg.png"
                alt=""
                className="absolute left-0 top-0 z-0 h-full w-full object-cover object-center opacity-[.32]"
            />
            <div className="relative z-[1] w-full px-8 lg:px-[120px]">
                {/* section content */}
                <div className="mx-auto flex w-full max-w-[1200px] flex-col items-center py-20 lg:py-[140px]">
                    {/* text group */}
                    <div className="flex max-w-[800px] flex-col items-center gap-6">
                        {/* title */}
                        <span className="text-center text-5xl font-bold text-[#fff] lg:text-[72px]">
                            About Prime Trade
                        </span>
                        {/* description */}
                        <span className="text-center text-base text-[#93b3bf]">
                            Trade Swiftly Trade Simply
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section1;
