import { useTranslation } from "react-i18next";

function Section2() {
    const { t } = useTranslation();

    return (
        <div className="w-full bg-[#171826] px-8 lg:px-[120px]">
            {/* section content */}
            <div className="mx-auto flex w-full max-w-[1200px] flex-col gap-8 py-10 lg:flex-row">
                <div className="flex w-full gap-4 lg:gap-8">
                    {/* block 1 */}
                    <div className="flex w-1/2 flex-col items-center gap-4">
                        <span className="text-center text-5xl font-bold text-[#47ddc2]">
                            50%
                        </span>
                        <span className="text-center text-base text-[#fff]">
                            {t("home.section2.minTradingAmount")}
                        </span>
                    </div>
                    {/* block 2 */}
                    <div className="flex w-1/2 flex-col items-center gap-4">
                        <span className="text-center text-5xl font-bold text-[#47ddc2]">
                            0%
                        </span>
                        <span className="text-center text-base text-[#fff]">
                            {t("home.section2.commission")}
                        </span>
                    </div>
                </div>
                <div className="flex w-full gap-4 lg:gap-8">
                    {/* block 3 */}
                    <div className="flex w-1/2 flex-col items-center gap-4">
                        <span className="text-center text-5xl font-bold text-[#47ddc2]">
                            100+
                        </span>
                        <span className="text-center text-base text-[#fff]">
                            {t("home.section2.numberOfProducts")}
                        </span>
                    </div>
                    {/* block 4 */}
                    <div className="flex w-1/2 flex-col items-center gap-4">
                        <span className="text-center text-5xl font-bold text-[#47ddc2]">
                            18
                        </span>
                        <span className="text-center text-base text-[#fff]">
                            {t("home.section2.coveredCountries")}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section2;
