import "./../policy.css";

function PrivacyPolicy() {
    return (
        <div className="w-full">
            <h1 className="text-[32px] text-[#fff]">PRIVACY POLICY</h1>
            <noindex>
                <ol>
                    <li>
                        By accessing https://www.1xprime.com/ and using
                        services provided by{" "}
                        <span style={{ "font-weight": "400" }}>HT Technology FZE</span>
                        , Client acknowledges and consents to his/her personal
                        data being collected and processed by{" "}
                        <span style={{ "font-weight": "400" }}>HT Technology FZE</span>
                        .
                    </li>
                    <li>
                        <span style={{ "font-weight": "400" }}>HT Technology FZE</span>
                        &nbsp;may collect the following information when you
                        register on https://zh-tw.expertoption.com/ or use
                        services provided by{" "}
                        <span style={{ "font-weight": "400" }}>HT Technology FZE</span>
                        :
                    </li>
                    <ul>
                        <li>name;</li>
                        <li>date of birth;</li>
                        <li>sex;</li>
                        <li>e‐mail address;</li>
                        <li>full mailing address;</li>
                        <li>phone number;</li>
                        <li>transactions details;</li>
                        <li>date of registration;</li>
                        <li>IP address;</li>
                        <li>geographical location;</li>
                        <li>browser type and version;</li>
                        <li>operating system;</li>
                        <li>referral source;</li>
                        <li>length of visit;</li>
                        <li>
                            <p>page views, etc.</p>
                        </li>
                    </ul>
                    <li>
                        When using https://www.1xprime.com/, software
                        applications or services, you may be required to provide
                        personal data that will be used by{" "}
                        <span style={{ "font-weight": "400" }}>HT Technology FZE</span>
                        &nbsp;to administer https://www.1xprime.com/,
                        applications, client databases, transactions and
                        marketing materials. By voluntarily proving your contact
                        details such as e-mail, telephone, and residential
                        address, you expressly consent to{" "}
                        <span style={{ "font-weight": "400" }}>HT Technology FZE</span>
                        &nbsp;to contact you using any of these methods.
                    </li>
                    <li>
                        All personal data collected by{" "}
                        <span style={{ "font-weight": "400" }}>HT Technology FZE</span>
                        &nbsp;will be treated as confidential and will be used
                        only for the following purposes:
                    </li>
                    <ul>
                        <li>
                            legal obligations to prevent money laundering and
                            terrorism financing;
                        </li>
                        <li>operating https://www.1xprime.com/</li>
                        <li>
                            providing, promoting, and improving our services;
                        </li>
                        <li>trading account set up and administration;</li>
                        <li>
                            ensuring the security of
                            https://www.1xprime.com/ and services;
                        </li>
                        <li>maintaining back-ups of our databases;</li>
                        <li>communicating with you;</li>
                        <li>keeping proper records;</li>
                        <li>
                            <p>statistical and marketing purposes.</p>
                        </li>
                    </ul>
                    <li>
                        On registration on&nbsp;Site with following address{" "}
                        <a href="https://www.1xprime.com/">
                            https://www.1xprime.com/
                        </a>{" "}
                        Client will have to&nbsp;provide certain identifying
                        details including, inter alia, for preventing money
                        laundering.
                    </li>
                    <li>
                        Client undertakes to&nbsp;provide true, accurate and
                        updated information about his identity and
                        is&nbsp;obliged not to&nbsp;impersonate to&nbsp;other
                        natural person. Any change in&nbsp;Client’s identifying
                        details must be&nbsp;notified to&nbsp;Company
                        immediately and in&nbsp;any case not later than the 30th
                        day from the change in&nbsp;such details.
                    </li>
                    <li>
                        Client’s details which were provided and/or will
                        be&nbsp;provided by&nbsp;Client during receiving
                        of&nbsp;the Services, Client’s registration on&nbsp;the
                        Site and Client’s activity on&nbsp;the Site may
                        be&nbsp;used by&nbsp;Company for sending Company’s
                        advertising content to&nbsp;Client.
                    </li>
                    <li>
                        Client details which were provided and/or will
                        be&nbsp;provided by&nbsp;Client during the activity
                        on&nbsp;the Site, may be&nbsp;disclosed by&nbsp;Company
                        to&nbsp;official authorities only if&nbsp;required
                        to&nbsp;be&nbsp;disclosed by&nbsp;Company according
                        to&nbsp;any applicable law and at&nbsp;the minimum
                        required extent.
                    </li>
                    <li>
                        Non-personal data information about Client can
                        be&nbsp;used by&nbsp;Company in&nbsp;any advertising
                        materials.
                    </li>
                    <li>
                        As a&nbsp;precondition for performing Operations
                        on&nbsp;the Site, Client may be&nbsp;asked
                        to&nbsp;provide certain identifying documents and any
                        other documents required by&nbsp;Company. If&nbsp;such
                        documents are not provided, Company have the right,
                        on&nbsp;its sole discretion, freeze the Client’s Account
                        for any period of&nbsp;time as&nbsp;well
                        as&nbsp;to&nbsp;permanently close the Client’s Account.
                        Without prejudice to&nbsp;the above, Company have the
                        right, at&nbsp;its sole discretion, to&nbsp;refuse
                        in&nbsp;opening of&nbsp;Client’s Account for any person
                        and for any reason, or&nbsp;without any reason.
                    </li>
                    <li>
                        Company shall not disclose any personal data
                        of&nbsp;existing Clients and former Clients unless
                        Client approved in&nbsp;writing by&nbsp;email such
                        disclosure or&nbsp;unless such disclosure
                        is&nbsp;required under applicable law to&nbsp;verify
                        Client’s identity. The Clients’ information
                        is&nbsp;passed only to&nbsp;Company’s employees dealing
                        with Client’s Accounts. All such information shall
                        be&nbsp;stored by&nbsp;Company on&nbsp;electronic and
                        physical storage media according to&nbsp;applicable law
                        requirements.
                    </li>
                    <li>
                        Client confirms and agrees that all information
                        in&nbsp;whole or&nbsp;in&nbsp;part concerning Client’s
                        Account and Operations will be&nbsp;stored
                        by&nbsp;Company and may be&nbsp;used by&nbsp;Company
                        in&nbsp;case of&nbsp;dispute between Client and Company.
                    </li>
                    <li>
                        At its sole discretion, Company have the right, but not
                        obliged, to&nbsp;review and inspect any information
                        provided by&nbsp;Client, for any purpose.
                        It&nbsp;is&nbsp;manifestly stated, and by&nbsp;its
                        signature hereunder Client also agrees, that Company
                        holds neither commitment nor responsibility
                        to&nbsp;Client due to&nbsp;any aforesaid review
                        or&nbsp;inspection of&nbsp;information.
                    </li>
                    <li>
                        Company will take measures to&nbsp;implement advanced
                        data protection procedures and to&nbsp;update them from
                        time to&nbsp;time for purpose of&nbsp;safeguarding
                        Client’s private information about Client, Client’s
                        Account and Operations.
                    </li>
                    <li>
                        Upon registration on&nbsp;the Site, Client will
                        be&nbsp;asked to&nbsp;choose a&nbsp;username and
                        password to&nbsp;be&nbsp;used by&nbsp;Client
                        on&nbsp;each future login and for the performance
                        of&nbsp;Operations and receiving of&nbsp;the Company’s
                        Services. In&nbsp;order to&nbsp;protect Clients’ privacy
                        and operation on&nbsp;the Site, sharing registration
                        details (including without limitation, username and
                        password) by&nbsp;Client with other persons
                        or&nbsp;legal entities is&nbsp;strictly prohibited.
                        Company shall not be&nbsp;held responsible for any
                        damage or&nbsp;losses caused to&nbsp;Client due
                        to&nbsp;improper use (including prohibited and
                        unprotected use) or&nbsp;storage of&nbsp;Client’s
                        username and password, including any such use made
                        by&nbsp;a&nbsp;third party, and whether or&nbsp;not
                        known to&nbsp;or&nbsp;authorized by&nbsp;Client.
                    </li>
                    <li>
                        Any use of&nbsp;the Site and Operation with the Client’s
                        login (email address) and password is&nbsp;Client’s sole
                        responsibility. Company shall not be&nbsp;held
                        responsible for any such use, including for validation
                        that Client is&nbsp;actually operating in&nbsp;Client’s
                        Account.
                    </li>
                    <li>
                        Client is&nbsp;obliged to&nbsp;forthwith notify the
                        Company’s client support service of&nbsp;any suspicion
                        for unauthorized use of&nbsp;the Client’s Account.
                    </li>
                    <li>
                        The Company does not store or collect any bank card
                        data. In accordance with the recommendations of Payment
                        Card Industry Security Standards Council, customer card
                        details are protected using Transport Layer encryption —
                        TLS 1.2 and application layer with algorithm AES and key
                        length 256 bit.
                    </li>
                    <li>
                        Client may send a request to{" "}
                        <a
                            className="c-link"
                            href="mailto:help@1xprime.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            data-sk="tooltip_parent"
                        >
                            help@eo.support
                        </a>
                        &nbsp;that Company deletes any personal information that
                        have been collected from/about Client. However, Company
                        reserves the right to retain personal information as
                        authorized under applicable law, such as personal
                        information required as necessary to provide our
                        services, protect our business and systems from
                        fraudulent activity, to debug and identify errors that
                        impair existing functionality, to comply with law
                        enforcement requests, to comply with legal obligations,
                        etc. &nbsp;If Client asks Company to delete information
                        necessary for the provision of services, Client may no
                        longer be able to access or use our services.
                    </li>
                    <li>
                        <span style={{ "font-weight": "400" }}>HT Technology FZE</span>
                        &nbsp;will keep your personal data for as long as you
                        use https://www.1xprime.com/ and other related
                        services and or as required by applicable laws and
                        regulations.
                        <br />
                        <span style={{ "font-weight": "400" }}>HT Technology FZE</span>
                        &nbsp;reserves the right to change this Privacy Policy
                        when deemed necessary or required by the law. Any
                        amendments will be immediately incorporated in the
                        existing Privacy Policy published on
                        https://www.1xprime.com/ and you are deemed to
                        have accepted a new version of Privacy Policy when you
                        first use https://www.1xprime.com/ following the
                        amendments.
                    </li>
                    <li>
                        Cookies Definition: A&nbsp;cookie is&nbsp;a&nbsp;some
                        set of&nbsp;data that often includes a&nbsp;unique
                        identifier that is&nbsp;sent to&nbsp;Client’s computer
                        or&nbsp;mobile phone (referred to&nbsp;here
                        as&nbsp;a&nbsp;“device” browser from a&nbsp;website’s
                        computer and is&nbsp;stored on&nbsp;Client’s device’s
                        hard drive for tracking site usage. A&nbsp;website may
                        send its own cookie to&nbsp;Client’s browser
                        if&nbsp;Client’s browser’s preferences allow&nbsp;it,
                        but, to&nbsp;protect Client’s privacy, Client’s browser
                        only permits a&nbsp;website to&nbsp;access the cookies
                        it&nbsp;has already sent to&nbsp;Client, not the cookies
                        sent to&nbsp;Client by&nbsp;other websites. Many
                        websites do&nbsp;this whenever a&nbsp;user visits their
                        website in&nbsp;order to&nbsp;track online traffic
                        flows. A&nbsp;Client may choose to&nbsp;configure their
                        browser to&nbsp;reject cookies by&nbsp;modifying their
                        browser settings or&nbsp;preferences.
                    </li>
                    <li>
                        Company’s cookies policy: During the course of&nbsp;any
                        Client’s visit to&nbsp;the Site, the pages viewed, along
                        with a&nbsp;cookie are downloaded to&nbsp;the Client’s
                        device hard drive, into Company’s local storage, and
                        also into the cache memory of&nbsp;the devices and
                        applications used by&nbsp;Client. Cookies stored may
                        determine the path Client took on&nbsp;the Site and used
                        to&nbsp;anonymously identify repeat users of&nbsp;the
                        Site and what pages were most popular for Company’s
                        clients. However, Company protects the Client’s privacy
                        by&nbsp;not storing the Client’s names, personal
                        details, emails, etc. Stored cookies allow the Site
                        to&nbsp;be&nbsp;more user-friendly and efficient for
                        Clients by&nbsp;allowing Company to&nbsp;inspect what
                        information is&nbsp;more valued by&nbsp;Clients versus
                        what isn’t.
                    </li>
                </ol>
            </noindex>
        </div>
    );
}

export default PrivacyPolicy;
