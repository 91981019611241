import { useTranslation } from "react-i18next";
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    IconButton,
} from "@material-tailwind/react";

function Header() {
    const { t, i18n } = useTranslation();

    function setLanguageOnclick(lang) {
        if (!lang) return;
        localStorage.setItem("lang", lang);
        i18n.changeLanguage(lang);
    }

    return (
        <div className="absolute left-0 top-0 z-[1] h-16 w-screen px-8 lg:h-20 lg:px-[120px]">
            {/* header content */}
            <div className="mx-auto flex h-full w-full max-w-[1200px] items-center justify-between">
                {/* logo */}
                <a href="/">
                    <img
                        src="assets/imgs/utils/logo/logo-row.png"
                        className="w-[120px] lg:w-[150px]"
                    />
                </a>
                {/* desktop navigators */}
                <div className="hidden items-center gap-16 lg:flex">
                    <a href="/markets" className="text-base text-[#fff]">
                        {t("components.header.markets")}
                    </a>
                    <a href="/trading" className="text-base text-[#fff]">
                        {t("components.header.trading")}
                    </a>
                    <a href="/support" className="text-base text-[#fff]">
                        {t("components.header.support")}
                    </a>
                    <a href="/aboutus" className="text-base text-[#fff]">
                        {t("components.header.aboutUs")}
                    </a>
                    <a
                        href="https://app.1xprime.com"
                        className="text-base text-[#fff]"
                    >
                        {t("components.header.login")}
                    </a>
                </div>
                <div className="flex items-center gap-5">
                    {/* language menu */}
                    <Menu placement="bottom-end">
                        <MenuHandler>
                            <IconButton
                                variant="text"
                                className="h-10 w-10 bg-transparent"
                            >
                                <img
                                    src={`assets/imgs/utils/locales/${i18n.language}.png`}
                                    alt=""
                                    className="h-6 w-6 min-w-6 rounded-full"
                                />
                            </IconButton>
                        </MenuHandler>
                        <MenuList>
                            <MenuItem
                                onClick={() => setLanguageOnclick("en")}
                                className="flex items-center gap-4 text-center"
                            >
                                <img
                                    src="assets/imgs/utils/locales/en.png"
                                    alt="language_english"
                                    className="h-6 w-6 min-w-6 rounded-full"
                                />
                                <span>English</span>
                            </MenuItem>
                            <MenuItem
                                onClick={() => setLanguageOnclick("vi")}
                                className="flex items-center gap-4 text-center"
                            >
                                <img
                                    src="assets/imgs/utils/locales/vi.png"
                                    alt="language_vietnam"
                                    className="h-6 w-6 min-w-6 rounded-full"
                                />
                                <span>Tiếng Việt</span>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    {/* mobile menu */}
                    <Menu placement="bottom-end">
                        <MenuHandler className="lg:hidden">
                            <IconButton variant="text" className="h-10 w-10">
                                <i className="fas fa-bars text-lg text-[#E5F0F4]" />
                            </IconButton>
                        </MenuHandler>
                        <MenuList>
                            <a href="/markets" className="outline-none">
                                <MenuItem className="text-center">
                                    {t("components.header.markets")}
                                </MenuItem>
                            </a>
                            <a href="/trading" className="outline-none">
                                <MenuItem className="text-center">
                                    {t("components.header.trading")}
                                </MenuItem>
                            </a>
                            <a href="/support" className="outline-none">
                                <MenuItem className="text-center">
                                    {t("components.header.support")}
                                </MenuItem>
                            </a>
                            <a href="/aboutus" className="outline-none">
                                <MenuItem className="text-center">
                                    {t("components.header.aboutUs")}
                                </MenuItem>
                            </a>
                            <MenuItem
                                onClick={() =>
                                    (window.location.href =
                                        "https://app.1xprime.com")
                                }
                                className="text-center"
                            >
                                {t("components.header.login")}
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>
        </div>
    );
}

export default Header;
