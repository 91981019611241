import { useTranslation } from "react-i18next";

function Section2() {
    const { t } = useTranslation();

    return (
        // wrapper
        <div className="w-full bg-[#fff] px-6 lg:px-[120px]">
            {/* section content */}
            <div className="mx-auto flex w-full max-w-[1200px] flex-col items-center gap-6 py-10 lg:gap-12 lg:py-20">
                {/* section component */}
                <div className="flex w-full flex-col gap-4 rounded-3xl bg-[#EAF5FA] p-4 lg:flex-row lg:gap-10 lg:rounded-3xl lg:p-10">
                    {/* component part1 */}
                    <div className="lg:gap-8] flex w-full flex-col gap-3">
                        <span className="text-2xl font-semibold text-[#151d24] lg:text-[32px]">
                            {t("market.section2.block1.titleText")}
                        </span>
                        <span className="text-base text-[#62737f]">
                            {t("market.section2.block1.description")}
                        </span>
                        <div className="flex w-full gap-2 lg:flex-grow lg:gap-[22px]">
                            <div className="flex w-1/3 flex-col justify-center gap-1 rounded-lg bg-[#fff] p-2 lg:gap-1 lg:p-4">
                                <span className="text-xs text-[#000] lg:text-base">
                                    {t(
                                        "market.section2.block1.spreadsTitleText",
                                    )}
                                </span>
                                <span className="text-base text-[#000] lg:text-2xl">
                                    {t(
                                        "market.section2.block1.spreadsContentText",
                                    )}
                                </span>
                            </div>
                            <div className="flex w-1/3 flex-col justify-center gap-1 rounded-lg bg-[#fff] p-2 lg:gap-1 lg:p-4">
                                <span className="text-xs text-[#000] lg:text-base">
                                    {t(
                                        "market.section2.block1.productsTitleText",
                                    )}
                                </span>
                                <span className="text-base text-[#000] lg:text-2xl">
                                    EUR, GBP, JPY, AUD
                                </span>
                            </div>
                            {/* <div className="flex w-1/3 flex-col justify-center gap-1 rounded-lg bg-[#fff] p-2 lg:gap-1 lg:p-4">
                                <span className="text-xs text-[#000] lg:text-base">
                                    See our
                                </span>
                                <span className="text-base text-[#000] lg:text-2xl">
                                    Spreads
                                </span>
                            </div> */}
                        </div>
                    </div>
                    {/* component part2 */}
                    <div className="flex w-full flex-col gap-3 rounded-lg bg-[#fff] p-6 lg:w-[320px]">
                        <div className="flex w-full flex-col gap-2">
                            <span className="text-base text-[#000]">
                                {t("market.section2.block1.markets")}
                            </span>
                            <span className="text-2xl font-semibold text-[#000]">
                                {t("market.section2.block1.forex")}
                            </span>
                        </div>
                        <div className="flex w-full flex-col">
                            <div className="flex w-full items-center gap-2 py-3">
                                <img
                                    src="assets/imgs/views/market/section2/icon-check.svg"
                                    alt=""
                                    className="h-3 w-3"
                                />
                                <span className="text-sm text-[#151d24]">
                                    {t(
                                        "market.section2.block1.marketsDescription1",
                                    )}
                                </span>
                            </div>
                            <div className="h-[1px] w-full bg-[#e5f0f4]"></div>
                            <div className="flex w-full items-center gap-2 py-3">
                                <img
                                    src="assets/imgs/views/market/section2/icon-check.svg"
                                    alt=""
                                    className="h-3 w-3"
                                />
                                <span className="text-sm text-[#151d24]">
                                    {t(
                                        "market.section2.block1.marketsDescription2",
                                    )}
                                </span>
                            </div>
                            <div className="h-[1px] w-full bg-[#e5f0f4]"></div>
                            <div className="flex w-full items-center gap-2 py-3">
                                <img
                                    src="assets/imgs/views/market/section2/icon-check.svg"
                                    alt=""
                                    className="h-3 w-3"
                                />
                                <span className="text-sm text-[#151d24]">
                                    {t(
                                        "market.section2.block1.marketsDescription3",
                                    )}
                                </span>
                            </div>
                            <div className="h-[1px] w-full bg-[#e5f0f4]"></div>
                            <div className="flex w-full items-center gap-2 py-3">
                                <img
                                    src="assets/imgs/views/market/section2/icon-check.svg"
                                    alt=""
                                    className="h-3 w-3"
                                />
                                <span className="text-sm text-[#151d24]">
                                    {t(
                                        "market.section2.block1.marketsDescription4",
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex w-full flex-col gap-4 rounded-3xl bg-[#EAF5FA] p-4 lg:flex-row lg:gap-10 lg:rounded-3xl lg:p-10">
                    {/* component part2 */}
                    <div className="flex w-full flex-col gap-3 rounded-lg bg-[#fff] p-6 lg:w-[320px]">
                        <div className="flex w-full flex-col gap-2">
                            <span className="text-base text-[#000]">
                                {t("market.section2.block2.markets")}
                            </span>
                            <span className="text-2xl font-semibold text-[#000]">
                                {t("market.section2.block2.indices")}
                            </span>
                        </div>
                        <div className="flex w-full flex-col">
                            <div className="flex w-full items-center gap-2 py-3">
                                <img
                                    src="assets/imgs/views/market/section2/icon-check.svg"
                                    alt=""
                                    className="h-3 w-3"
                                />
                                <span className="text-sm text-[#151d24]">
                                    {t(
                                        "market.section2.block1.marketsDescription1",
                                    )}
                                </span>
                            </div>
                            <div className="h-[1px] w-full bg-[#e5f0f4]"></div>
                            <div className="flex w-full items-center gap-2 py-3">
                                <img
                                    src="assets/imgs/views/market/section2/icon-check.svg"
                                    alt=""
                                    className="h-3 w-3"
                                />
                                <span className="text-sm text-[#151d24]">
                                    {t(
                                        "market.section2.block1.marketsDescription2",
                                    )}
                                </span>
                            </div>
                            <div className="h-[1px] w-full bg-[#e5f0f4]"></div>
                            <div className="flex w-full items-center gap-2 py-3">
                                <img
                                    src="assets/imgs/views/market/section2/icon-check.svg"
                                    alt=""
                                    className="h-3 w-3"
                                />
                                <span className="text-sm text-[#151d24]">
                                    {t(
                                        "market.section2.block1.marketsDescription3",
                                    )}
                                </span>
                            </div>
                            <div className="h-[1px] w-full bg-[#e5f0f4]"></div>
                            <div className="flex w-full items-center gap-2 py-3">
                                <img
                                    src="assets/imgs/views/market/section2/icon-check.svg"
                                    alt=""
                                    className="h-3 w-3"
                                />
                                <span className="text-sm text-[#151d24]">
                                    {t(
                                        "market.section2.block1.marketsDescription4",
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* component part1 */}
                    <div className="lg:gap-8] flex w-full flex-col gap-3">
                        <span className="text-2xl font-semibold text-[#151d24] lg:text-[32px]">
                            {t("market.section2.block1.titleText")}
                        </span>
                        <span className="text-base text-[#62737f]">
                            {t("market.section2.block1.description")}
                        </span>
                        <div className="flex w-full gap-2 lg:flex-grow lg:gap-[22px]">
                            <div className="flex w-1/3 flex-col justify-center gap-1 rounded-lg bg-[#fff] p-2 lg:gap-1 lg:p-4">
                                <span className="text-xs text-[#000] lg:text-base">
                                    {t(
                                        "market.section2.block1.spreadsTitleText",
                                    )}
                                </span>
                                <span className="text-base text-[#000] lg:text-2xl">
                                    {t(
                                        "market.section2.block1.spreadsContentText",
                                    )}
                                </span>
                            </div>
                            <div className="flex w-1/3 flex-col justify-center gap-1 rounded-lg bg-[#fff] p-2 lg:gap-1 lg:p-4">
                                <span className="text-xs text-[#000] lg:text-base">
                                    {t(
                                        "market.section2.block1.productsTitleText",
                                    )}
                                </span>
                                <span className="text-base text-[#000] lg:text-2xl">
                                    HSI, HHI, VNI, DOW, NAQ
                                </span>
                            </div>
                            {/* <div className="flex w-1/3 flex-col justify-center gap-1 rounded-lg bg-[#fff] p-2 lg:gap-1 lg:p-4">
                                <span className="text-xs text-[#000] lg:text-base">
                                    See our
                                </span>
                                <span className="text-base text-[#000] lg:text-2xl">
                                    Spreads
                                </span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section2;
