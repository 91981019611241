import Section1 from "./components/Section1";
import Section2 from "./components/Section2";

function Market() {
    return (
        <div className="flex w-full flex-col">
            <Section1 />
            <Section2 />
        </div>
    );
}

export default Market;
