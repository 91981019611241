import { useTranslation } from "react-i18next";

function Section6() {
    const { t } = useTranslation();

    return (
        <div className="w-full bg-[#fff] px-8 lg:px-[120px]">
            <div className="mx-auto flex w-full max-w-[1200px] flex-col gap-6 py-16 lg:gap-20 lg:pb-[120px] lg:pt-[100px]">
                {/* text content */}
                <div className="flex w-full flex-col gap-4">
                    <span className="text-center text-[40px] font-bold text-[#0066ff] lg:text-[56px]">
                        {t("home.section6.titleMainText")}
                    </span>
                    <span className="text-center text-[40px] font-bold text-[#151d24] lg:text-[56px]">
                        {t("home.section6.titleSubText")}
                    </span>
                </div>
                {/* description image group */}
                <div className="flex w-full flex-col items-center gap-10 lg:flex-row">
                    {/* group 1 */}
                    <div className="flex w-full flex-col items-center gap-4 lg:w-1/3">
                        <img
                            src="assets/imgs/views/home/section6/description_image-1.png"
                            alt=""
                            className="w-[100px]"
                        />
                        <span className="text-center text-xl font-semibold text-[#151d24] lg:text-2xl">
                            {t("home.section6.contentText1")}
                        </span>
                    </div>
                    {/* group 2 */}
                    <div className="flex w-full flex-col items-center gap-4 lg:w-1/3">
                        <img
                            src="assets/imgs/views/home/section6/description_image-2.png"
                            alt=""
                            className="w-[100px]"
                        />
                        <span className="text-center text-xl font-semibold text-[#151d24] lg:text-2xl">
                            {t("home.section6.contentText2")}
                        </span>
                    </div>
                    {/* group 3 */}
                    <div className="flex w-full flex-col items-center gap-4 lg:w-1/3">
                        <img
                            src="assets/imgs/views/home/section6/description_image-3.png"
                            alt=""
                            className="w-[100px]"
                        />
                        <span className="text-center text-xl font-semibold text-[#151d24] lg:text-2xl">
                            {t("home.section6.contentText3")}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section6;
